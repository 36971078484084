<ng-container *ngrxLet="{ templates: templates$ } as vm">
    <form class="form-content" [formGroup]="alertForm">
        <dtm-ui-radio-tile-group [formControl]="alertForm.controls.type" (change)="setAlertType($event.value)" class="alert-type-tiles">
            <dtm-ui-radio-tile [value]="AlertType.NoFlyZone">
                <p class="selectable-tile-label">
                    {{ "datsLibAlert.alertForms.noFlyZoneTileLabel" | transloco }}
                </p>
            </dtm-ui-radio-tile>
            <dtm-ui-radio-tile [value]="AlertType.Message">
                <p class="selectable-tile-label">
                    {{ "datsLibAlert.alertForms.messageTileLabel" | transloco }}
                </p>
            </dtm-ui-radio-tile>
            <dtm-ui-radio-tile [value]="AlertType.Modification" disabled>
                <p class="selectable-tile-label">
                    {{ "datsLibAlert.alertForms.modifyTileLabel" | transloco }}
                </p>
            </dtm-ui-radio-tile>
        </dtm-ui-radio-tile-group>

        <div [ngSwitch]="alertForm.controls.type.value">
            <ng-content select="[contentSlot]"></ng-content>
            <ng-template *ngTemplateOutlet="messageFormContentTemplate"></ng-template>
            <div class="no-fly-zone-form" *ngSwitchCase="AlertType.NoFlyZone">
                <div class="sub-header">{{ "datsLibAlert.alertForms.nfzTimeRangeHeader" | transloco }}</div>
                <dats-lib-alert-time-range [formControl]="alertForm.controls.timeRange"></dats-lib-alert-time-range>
            </div>
            <div class="message-form" *ngSwitchCase="AlertType.Message">
                <span class="message-duration-header">{{ "datsLibAlert.alertForms.messageDurationHeader" | transloco }}</span>
                <dtm-ui-radio-tile-group class="message-duration-type-tiles" [value]="alertForm.controls.timeRange.value !== null">
                    <dtm-ui-radio-tile [value]="false" (click)="alertForm.controls.timeRange.setValue(null)">
                        <p class="selectable-tile-label">
                            {{ "datsLibAlert.alertForms.untilCancelledTileLabel" | transloco }}
                        </p>
                    </dtm-ui-radio-tile>
                    <dtm-ui-radio-tile [value]="true" (click)="setLimitedTimeRange()">
                        <p class="selectable-tile-label">
                            {{ "datsLibAlert.alertForms.limitedTimeTileLabel" | transloco }}
                        </p>
                    </dtm-ui-radio-tile>
                </dtm-ui-radio-tile-group>
                <dats-lib-alert-time-range
                    *ngIf="alertForm.controls.timeRange.value !== null"
                    [formControl]="alertForm.controls.timeRange"
                ></dats-lib-alert-time-range>
            </div>
            <div class="modify-form" *ngSwitchCase="AlertType.Modification"></div>
        </div>
    </form>

    <ng-template #messageFormContentTemplate>
        <dtm-ui-select-field
            [placeholder]="'datsLibAlert.alertForms.selectPlaceholder' | transloco"
            [isClearable]="false"
            [formControl]="alertForm.controls.messageTemplateId"
            (selectionChange)="setMessageFromTemplate($event.value)"
        >
            <label>{{ "datsLibAlert.alertForms.messageTemplateLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let template of vm.templates" [value]="template.value">
                {{ template.value | systemTranslation }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>

        <dtm-ui-textarea-field [maxLength]="MAX_MESSAGE_LENGTH">
            <label *ngIf="alertForm.controls.type.value === AlertType.Message">{{
                "datsLibAlert.alertForms.messageLabel" | transloco
            }}</label>
            <label *ngIf="alertForm.controls.type.value !== AlertType.Message">{{
                "datsLibAlert.alertForms.optionalMessageLabel" | transloco
            }}</label>
            <textarea
                matInput
                [formControl]="alertForm.controls.message"
                [placeholder]="'datsLibAlert.alertForms.messagePlaceholder' | transloco"
            ></textarea>
            <div class="field-error" *dtmUiFieldHasError="alertForm.controls.message; name: 'required'">
                {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="alertForm.controls.message; name: 'maxlength'; error as error">
                {{ "datsLibAlert.alertForms.messageMaxLengthErrorMessage" | transloco : { maxLength: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>
    </ng-template>
</ng-container>
