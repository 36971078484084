import { inject, Injectable } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store";
import { catchError, EMPTY, finalize, first, tap } from "rxjs";
import { DATSFeatures } from "../../capabilities/models/capabilities.model";
import { CapabilitiesState } from "../../capabilities/state/capabilities.state";
import { Error } from "../../shared/models/error.model";
import {
    FlightCategory,
    FlightDetails,
    FlightItem,
    FlightList,
    FlightsErrorType,
    FlightsTab,
    FlightsTabType,
    JurisdictionMission,
    TechnicalCheckinData,
} from "../models/flight.models";
import { FlightFilterType } from "../models/flights-filters.models";
import { FlightsApiService } from "../services/flights-api.service";
import { FlightsActions } from "./flights.actions";
import { getDefaultTabs, getFilteredFlightList, getFlightsTabs, mergeTabs } from "./flights.state.helpers";

export const FLIGHTS_TECHNICAL_CHECKIN_DATA_STORAGE_KEY = "flights.lastTechnicalCheckinData";

export interface FlightsStateModel {
    error: Error<FlightsErrorType> | undefined;
    isPanelProcessing: boolean;
    isActionProcessing: boolean;
    allFlights: FlightItem[] | undefined;
    flightDetails: FlightDetails | undefined;
    tabs: FlightsTab[];
    filteredFlightList: FlightList | undefined;
    selectedTabType: FlightsTabType | undefined;
    selectedFlightId: string | undefined;
    appliedFilters: FlightFilterType[];
    isAtcController: boolean;
    jurisdictionMissions: JurisdictionMission[];
    isHeightFilterDialogOpen: boolean;
    heightFilter: number | undefined;
    lastTechnicalCheckinData: TechnicalCheckinData | undefined;
}

export type CategorizedFlights = Partial<Record<FlightCategory, FlightItem[]>>;

const defaultState: FlightsStateModel = {
    error: undefined,
    isPanelProcessing: false,
    isActionProcessing: false,
    allFlights: undefined,
    flightDetails: undefined,
    tabs: [],
    filteredFlightList: undefined,
    selectedTabType: undefined,
    selectedFlightId: undefined,
    appliedFilters: [],
    isAtcController: true,
    jurisdictionMissions: [],
    isHeightFilterDialogOpen: false,
    heightFilter: undefined,
    lastTechnicalCheckinData: undefined,
};

@State<FlightsStateModel>({
    name: "flights",
    defaults: defaultState,
})
@UntilDestroy()
@Injectable()
export class FlightsState implements NgxsOnInit {
    private readonly store = inject(Store);

    @Selector()
    public static error(state: FlightsStateModel): Error<FlightsErrorType> | undefined {
        return state.error;
    }

    @Selector()
    public static isPanelProcessing(state: FlightsStateModel): boolean {
        return state.isPanelProcessing;
    }

    @Selector()
    public static isActionProcessing(state: FlightsStateModel): boolean {
        return state.isActionProcessing;
    }

    @Selector()
    public static allFlights(state: FlightsStateModel): FlightItem[] | undefined {
        return state.allFlights;
    }

    @Selector()
    public static flightDetails(state: FlightsStateModel): FlightDetails | undefined {
        return state.flightDetails;
    }

    @Selector()
    public static selectedFlightId(state: FlightsStateModel): string | undefined {
        return state.selectedFlightId;
    }

    @Selector()
    public static appliedFilters(state: FlightsStateModel): FlightFilterType[] {
        return state.appliedFilters;
    }

    @Selector()
    public static isAtcController(state: FlightsStateModel): boolean {
        return state.isAtcController;
    }

    @Selector()
    public static selectedTabType(state: FlightsStateModel): FlightsTabType | undefined {
        return state.selectedTabType;
    }

    @Selector()
    public static filteredFlightList(state: FlightsStateModel): FlightList | undefined {
        return state.filteredFlightList;
    }

    @Selector()
    public static tabs(state: FlightsStateModel) {
        return state.tabs;
    }

    @Selector()
    public static jurisdictionMissions(state: FlightsStateModel) {
        return state.jurisdictionMissions;
    }

    @Selector()
    public static heightFilter(state: FlightsStateModel) {
        return state.heightFilter;
    }

    @Selector()
    public static isHeightFilterDialogOpen(state: FlightsStateModel) {
        return state.isHeightFilterDialogOpen;
    }

    @Selector()
    public static lastTechnicalCheckinData(state: FlightsStateModel) {
        return state.lastTechnicalCheckinData;
    }

    constructor(private readonly flightsApi: FlightsApiService) {}

    public ngxsOnInit(context: StateContext<FlightsStateModel>) {
        this.store
            .select(CapabilitiesState.permissions)
            .pipe(untilDestroyed(this))
            .subscribe((permissions) => {
                const isAtcController = !permissions?.find((permission) => permission === DATSFeatures.ServiceFlightInformation);
                const state = context.getState();

                if (context.getState().isAtcController !== isAtcController) {
                    context.patchState({
                        isAtcController,
                        tabs: this.getTabs(isAtcController, state.allFlights, state.jurisdictionMissions),
                    });
                }
            });

        this.store
            .select(AuthState.isLoggedIn)
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe(() => this.store.dispatch(new FlightsActions.WatchFlights()));
    }

    @Action(FlightsActions.GetFlights)
    public getFlights(context: StateContext<FlightsStateModel>) {
        context.patchState({ isPanelProcessing: true, error: undefined });

        return this.flightsApi.getFlights().pipe(
            tap((flightItems: FlightItem[]) => {
                const state = context.getState();
                context.patchState({
                    isPanelProcessing: false,
                    allFlights: flightItems,
                    filteredFlightList: getFilteredFlightList(
                        flightItems,
                        state.isAtcController,
                        state.selectedTabType,
                        state.appliedFilters
                    ),
                    tabs: this.getTabs(state.isAtcController, flightItems, state.jurisdictionMissions),
                });

                const selectedFlightId = state.selectedFlightId;
                if (flightItems && selectedFlightId) {
                    const doSelectedFlightStillExist = flightItems.some((flight) => flight.id === selectedFlightId);

                    if (!doSelectedFlightStillExist) {
                        context.patchState({ selectedFlightId: undefined });
                    }
                }
            }),
            catchError((error) => {
                context.patchState({
                    isPanelProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightsActions.GetFlightDetails)
    public getFlight(context: StateContext<FlightsStateModel>, action: FlightsActions.GetFlightDetails) {
        context.patchState({ isPanelProcessing: true, error: undefined, flightDetails: undefined });

        return this.flightsApi.getFlightDetails(action.flightId).pipe(
            tap((flightDetails) => {
                context.patchState({
                    isPanelProcessing: false,
                    flightDetails,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isPanelProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightsActions.GetJurisdictionMissionList)
    public getJurisdictionMissions(context: StateContext<FlightsStateModel>) {
        context.patchState({ isPanelProcessing: true, error: undefined });

        return this.flightsApi.getJurisdictionMissionList().pipe(
            tap((response) => {
                const state = context.getState();
                context.patchState({
                    isPanelProcessing: false,
                    jurisdictionMissions: response,
                    tabs: this.getTabs(state.isAtcController, state.allFlights, response),
                });
            }),
            catchError((error) => {
                context.patchState({
                    isPanelProcessing: false,
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightsActions.GetJurisdictionMission)
    public getJurisdictionMission(context: StateContext<FlightsStateModel>, action: FlightsActions.GetJurisdictionMission) {
        context.patchState({ isPanelProcessing: true, error: undefined });

        const jurisdictionMissions = context.getState().jurisdictionMissions;

        return this.flightsApi.getJurisdictionMission(action.id).pipe(
            tap((mission) => {
                context.patchState({
                    isPanelProcessing: false,
                    jurisdictionMissions: jurisdictionMissions?.map((existingMission) =>
                        existingMission.missionId === mission.missionId ? mission : existingMission
                    ) ?? [mission],
                });
            }),
            catchError((error) => {
                context.patchState({ isPanelProcessing: false, error });

                return EMPTY;
            })
        );
    }

    @Action(FlightsActions.SelectFlightsTab)
    public selectFlightsTab(context: StateContext<FlightsStateModel>, action: FlightsActions.SelectFlightsTab) {
        const state = context.getState();
        const filteredFlightList: FlightList | undefined = !state.allFlights
            ? undefined
            : getFilteredFlightList(state.allFlights, state.isAtcController, action.tabType, state.appliedFilters);

        context.patchState({
            selectedTabType: action.tabType,
            filteredFlightList,
            selectedFlightId:
                !action.tabType || filteredFlightList?.visibleFlights.some((flight) => flight.id === state.selectedFlightId)
                    ? state.selectedFlightId
                    : undefined,
        });
    }

    @Action(FlightsActions.SelectFlight)
    public selectFlight(context: StateContext<FlightsStateModel>, action: FlightsActions.SelectFlight) {
        const state = context.getState();
        context.patchState({
            selectedFlightId: action.flightId,
        });

        if (action.flightId && !state.filteredFlightList?.visibleFlights.some((flight) => flight.id === action.flightId)) {
            context.dispatch(new FlightsActions.SelectFlightsTab(undefined));
        }
    }

    @Action(FlightsActions.ApplyFilters)
    public applyFilters(context: StateContext<FlightsStateModel>, action: FlightsActions.ApplyFilters) {
        const state = context.getState();
        context.patchState({
            appliedFilters: action.filters,
            filteredFlightList: !state.allFlights
                ? undefined
                : getFilteredFlightList(state.allFlights, state.isAtcController, state.selectedTabType, action.filters),
        });
    }

    @Action(FlightsActions.ClearCategorizedFlights)
    public clearCategorizedFlights(context: StateContext<FlightsStateModel>) {
        context.patchState({ allFlights: undefined, selectedFlightId: undefined });
    }

    @Action(FlightsActions.StandbyCheckin)
    public standbyCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.StandbyCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.standbyCheckin(action.checkinId, action.duration).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ModifyCheckin)
    public modifyCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.ModifyCheckinAndAccept) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.modifyCheckin(action.checkinId, action.formValues).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ModifyCheckinAndAccept)
    public modifyCheckinAndAccept(context: StateContext<FlightsStateModel>, action: FlightsActions.ModifyCheckinAndAccept) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.modifyCheckinAndAccept(action.checkinId, action.formValues).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.AcceptCheckin)
    public acceptCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.AcceptCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.acceptCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.RejectCheckin)
    public rejectCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.RejectCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.rejectCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.StopCheckin)
    public stopCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.StopCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.stopCheckin(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ArchiveCheckin)
    public archiveCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.ArchiveCheckin) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.archiveCheckin(action.checkinId, action.formValues).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.ResendCheckinConfirmation)
    public resendCheckinConfirmation(context: StateContext<FlightsStateModel>, action: FlightsActions.ResendCheckinConfirmation) {
        context.patchState({ isActionProcessing: true, error: undefined });

        return this.flightsApi.resendCheckinConfirmation(action.checkinId).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(FlightsActions.SetIsHeightFilterDialogOpen)
    public setIsHeightFilterDialogOpen(context: StateContext<FlightsStateModel>, action: FlightsActions.SetIsHeightFilterDialogOpen) {
        context.patchState({ isHeightFilterDialogOpen: action.isOpen });
    }

    @Action(FlightsActions.SetHeightFilter)
    public setHeightFilter(context: StateContext<FlightsStateModel>, action: FlightsActions.SetHeightFilter) {
        context.patchState({ heightFilter: action.height });
    }

    @Action(FlightsActions.CreateTechnicalCheckin)
    public createTechnicalCheckin(context: StateContext<FlightsStateModel>, action: FlightsActions.CreateTechnicalCheckin) {
        context.patchState({ lastTechnicalCheckinData: action.checkinData });

        return this.flightsApi.createTechnicalCheckin(action.checkinData).pipe(
            tap(() => context.dispatch(new FlightsActions.GetFlights())),
            catchError((error) => {
                context.patchState({ error });

                return EMPTY;
            })
        );
    }

    @Action(FlightsActions.WatchFlights)
    public watchFlights(context: StateContext<FlightsStateModel>) {
        return this.flightsApi.startFlightsWatch().pipe(
            tap((message) => {
                // TODO: DTATS-373 - do something with the messages
                console.log(message);
            })
        );
    }

    private getTabs(
        isAtcController: boolean,
        flights: FlightItem[] | undefined,
        jurisdictionMissions: JurisdictionMission[]
    ): FlightsTab[] {
        let tabs = getDefaultTabs(isAtcController);
        if (flights) {
            tabs = mergeTabs(tabs, getFlightsTabs(flights, isAtcController));
        }

        if (jurisdictionMissions.length === 0) {
            return tabs;
        }

        return mergeTabs(tabs, [
            {
                type: FlightsTabType.Mission,
                total: jurisdictionMissions.length,
                isActionRequired: false,
            },
        ]);
    }
}
