import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { PushPipe } from "@ngrx/component";

@Component({
    selector: "dats-lib-action-sidebar-header",
    standalone: true,
    imports: [SharedUiModule, PushPipe],
    templateUrl: "./action-sidebar-header.component.html",
    styleUrls: ["./action-sidebar-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionSidebarHeaderComponent {
    @Output() public readonly actionCancel = new EventEmitter<void>();
}
