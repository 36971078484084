<dtm-ui-expandable-panel *ngIf="operator$ | ngrxPush as operator" [hasHeaderSeparator]="false">
    <div headerSlot>
        {{ "datsLibFlights.missionDetails.operatorLabel" | transloco }}
    </div>
    <div class="content">
        <dtm-ui-label-value
            *ngIf="operator.name"
            [label]="'datsLibFlights.missionDetails.operatorNameLabel' | transloco"
            [value]="operator.name"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="operator.mobile"
            [label]="'datsLibFlights.missionDetails.operatorMobileNumberLabel' | transloco"
            [value]="operator.mobile"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="operator.operatorNumber"
            [label]="'datsLibFlights.missionDetails.operatorNumberLabel' | transloco"
            [value]="operator.operatorNumber"
        ></dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
