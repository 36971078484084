<ng-container
    *ngrxLet="{
        zones: zones$,
        templates: templates$,
        selectedType: selectedType$,
        airspaceAutocompletionList: airspaceAutocompletionList$,
        isAirspaceAutocompletionProcessing: isAirspaceAutocompletionProcessing$
    } as vm"
>
    <dats-lib-action-sidebar-header (actionCancel)="actionCancel.emit()">
        {{ "datsLibAlert.alertForms.airspaceAlertForm.header" | transloco }}
    </dats-lib-action-sidebar-header>
    <dats-lib-alert-form
        class="action-sidebar-content scroll-shadows"
        [templates]="vm.templates"
        [isMessageTemplateIdSelectionDisabled]="!zoneFormControl.value"
        cdkScrollable
    >
        <dats-lib-alert-zone
            class="alert-zone"
            contentSlot
            [options]="vm.airspaceAutocompletionList"
            [isProcessing]="vm.isAirspaceAutocompletionProcessing"
            [formControl]="zoneFormControl"
            (searchTextChange)="searchTextChange($event)"
        >
            <div class="field-error" *dtmUiFieldHasError="zoneFormControl; name: 'required'">
                {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
            </div>
        </dats-lib-alert-zone>
    </dats-lib-alert-form>
    <dats-lib-action-sidebar-footer
        (actionCancel)="actionCancel.emit()"
        (actionConfirm)="tryEmitConfirm()"
        [confirmButtonText]="'datsLibAlert.alertForms.saveButtonLabel' | transloco : { value: vm.selectedType }"
    >
    </dats-lib-action-sidebar-footer>
</ng-container>
