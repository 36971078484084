import { Injectable, inject } from "@angular/core";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, catchError, finalize, tap } from "rxjs";
import { Error } from "../../shared/models/error.model";
import {
    AlertErrorType,
    AlertGeometrySourceType,
    AlertPointOfInterest,
    AlertProposal,
    CategorizedAlertsByBadge,
    PoiType,
    Zone,
} from "../models/alert.models";
import { AlertApiService } from "../services/alert-api.service";
import { AlertActions } from "./alert.actions";

export interface AlertStateModel {
    error: Error<AlertErrorType> | undefined;
    isActionProcessing: boolean;
    selectedAlertGeometrySourceType: AlertGeometrySourceType | undefined;
    alerts: CategorizedAlertsByBadge | undefined;
    alertProposal: AlertProposal | undefined;
    hospitalList: AlertPointOfInterest[] | undefined;
    zoneList: AlertPointOfInterest[] | undefined;
    hemsBaseList: AlertPointOfInterest[] | undefined;
    airspaceAutocompletionList: Zone[] | undefined;
    isAirspaceAutocompletionProcessing: boolean;
}

const defaultState: AlertStateModel = {
    error: undefined,
    isActionProcessing: false,
    selectedAlertGeometrySourceType: undefined,
    alerts: undefined,
    alertProposal: undefined,
    hospitalList: undefined,
    zoneList: undefined,
    hemsBaseList: undefined,
    airspaceAutocompletionList: undefined,
    isAirspaceAutocompletionProcessing: false,
};

@State<AlertStateModel>({
    name: "alert",
    defaults: defaultState,
})
@Injectable()
export class AlertState {
    private alertApi = inject(AlertApiService);

    @Selector()
    public static error(state: AlertStateModel): Error<AlertErrorType> | undefined {
        return state.error;
    }

    @Selector()
    public static isActionProcessing(state: AlertStateModel): boolean {
        return state.isActionProcessing;
    }

    @Selector()
    public static selectedAlertGeometrySourceType(state: AlertStateModel): AlertGeometrySourceType | undefined {
        return state.selectedAlertGeometrySourceType;
    }

    @Selector()
    public static alerts(state: AlertStateModel) {
        return state.alerts;
    }

    @Selector()
    public static alertProposal(state: AlertStateModel): AlertProposal | undefined {
        return state.alertProposal;
    }

    @Selector()
    public static hospitalList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.hospitalList;
    }

    @Selector()
    public static hemsBaseList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.hemsBaseList;
    }

    @Selector()
    public static zoneList(state: AlertStateModel): AlertPointOfInterest[] | undefined {
        return state.zoneList;
    }

    @Selector()
    public static airspaceAutocompletionList(state: AlertStateModel): Zone[] | undefined {
        return state.airspaceAutocompletionList;
    }

    @Selector()
    public static isAirspaceAutocompletionProcessing(state: AlertStateModel): boolean {
        return state.isAirspaceAutocompletionProcessing;
    }

    @Action(AlertActions.SelectAlertGeometrySourceType)
    public selectAlertGeometrySourceType(context: StateContext<AlertStateModel>, action: AlertActions.SelectAlertGeometrySourceType) {
        context.patchState({ selectedAlertGeometrySourceType: action.type });
    }

    @Action(AlertActions.GetAlerts)
    public getAlerts(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, alertProposal: undefined, isActionProcessing: true });

        return this.alertApi.getAlerts().pipe(
            tap((alerts) => {
                context.patchState({ alerts });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAlertProposal)
    public getAlertProposal(context: StateContext<AlertStateModel>, action: AlertActions.GetAlertProposal) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getAlertProposal(action.params.type, action.params.poiId, action.params.designator).pipe(
            tap((alertProposal) => {
                context.patchState({ alertProposal });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    alertProposal: undefined,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.CreateAlert)
    public createAlert(context: StateContext<AlertStateModel>, action: AlertActions.CreateAlert) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.createAlert(action.alertEntity).pipe(
            tap(() => context.dispatch(new AlertActions.GetAlerts())),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllHospitals)
    public getAllHospitals(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(false, PoiType.Hospital).pipe(
            tap(({ content: hospitalList }) => {
                context.patchState({
                    hospitalList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllZones)
    public getAllZones(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(false, PoiType.Airspace).pipe(
            tap(({ content: zoneList }) => {
                context.patchState({
                    zoneList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllHemsBases)
    public getAllHemsBases(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(false, PoiType.Hems).pipe(
            tap(({ content: hemsBaseList }) => {
                context.patchState({
                    hemsBaseList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAllBookmarkedPois)
    public getAllBookmarkedPois(context: StateContext<AlertStateModel>) {
        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.getPoiList(true).pipe(
            tap(({ content }) => {
                const [zoneList, poisWithoutZones] = ArrayUtils.partition(content, (poi) => poi.type === PoiType.Airspace);
                const [hospitalList, poisWithoutHospitals] = ArrayUtils.partition(poisWithoutZones, (poi) => poi.type === PoiType.Hospital);
                const hemsBaseList = poisWithoutHospitals.filter((poi) => poi.type === PoiType.Hems);

                context.patchState({
                    zoneList,
                    hospitalList,
                    hemsBaseList,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.SetBookmarkedHospitals)
    public setBookmarkedHospitals(context: StateContext<AlertStateModel>, action: AlertActions.SetBookmarkedHospitals) {
        const bookmarkedHospitals = context.getState().hospitalList;

        if (!bookmarkedHospitals) {
            return;
        }

        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.setBookmarkedHospitals(bookmarkedHospitals, action.newBookmarkedHospitals).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }

    @Action(AlertActions.GetAirspaceAutocompletion)
    public getAirspaceAutocompletion(context: StateContext<AlertStateModel>, action: AlertActions.GetAirspaceAutocompletion) {
        context.patchState({ isAirspaceAutocompletionProcessing: true, airspaceAutocompletionList: undefined });

        return this.alertApi.getAirspaceAutocompletion(action.value).pipe(
            tap((airspaceAutocompletionList) => {
                context.patchState({ airspaceAutocompletionList });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isAirspaceAutocompletionProcessing: false }))
        );
    }

    @Action(AlertActions.SetBookmarkedHemsBases)
    public setBookmarkedHemsBases(context: StateContext<AlertStateModel>, action: AlertActions.SetBookmarkedHemsBases) {
        const bookmarkedHemsBases = context.getState().hemsBaseList;

        if (!bookmarkedHemsBases) {
            return;
        }

        context.patchState({ error: undefined, isActionProcessing: true });

        return this.alertApi.setBookmarkedHemsBases(bookmarkedHemsBases, action.newBookmarkedHemsBases).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionProcessing: false }))
        );
    }
}
