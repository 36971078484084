import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { LEAFLET_MAP_CONFIG, LeafletMapConfig, WGS84Coordinates } from "@dtm-frontend/shared/map/leaflet";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { LatLngBounds, MapOptions } from "leaflet";
import { EMPTY, combineLatest, distinctUntilChanged, filter, firstValueFrom, map, pairwise, startWith, switchMap } from "rxjs";
import { AirspaceAlertFormGroupValue } from "../../../alert/components/alert-forms/airspace-alert-form/airspace-alert-form.component";
import { HemsAlertFormGroupValue } from "../../../alert/components/alert-forms/hems-alert-form/hems-alert-form.component";
import { HospitalAlertFormGroupValue } from "../../../alert/components/alert-forms/hospital-alert-form/hospital-alert-form.component";
import {
    AlertAreaType,
    AlertErrorType,
    AlertGeometrySourceType,
    AlertPointOfInterest,
    AlertProposalQueryParams,
    PoiAlertEntity,
    PoiType,
    ZoneAlertEntity,
} from "../../../alert/models/alert.models";
import { AlertActions } from "../../../alert/state/alert.actions";
import { AlertState } from "../../../alert/state/alert.state";
import { DATSFeatures } from "../../../capabilities/models/capabilities.model";
import { CapabilitiesState } from "../../../capabilities/state/capabilities.state";
import { ErrorHandlingService } from "../../../shared/services/error-handling-service";
import {
    CheckinAction,
    CheckinActionType,
    FlightAcceptancePhase,
    FlightItem,
    FlightsErrorType,
    FlightsTab,
    ModifyCheckinFormValues,
    TechnicalCheckinData,
} from "../../models/flight.models";
import { FlightFilterType } from "../../models/flights-filters.models";
import { FlightsActions } from "../../state/flights.actions";
import { FlightsState } from "../../state/flights.state";
import { ArchiveDialogComponent } from "../archive-dialog/archive-dialog.component";
import { FlightsLayerDirective } from "../flights-layer/flights-layer.directive";
import { FlightsPanelComponent } from "../flights-panel/flights-panel.component";

enum ActionSidebarType {
    Checkin = "Checkin",
    Hospital = "Hospital",
    HemsBase = "HemsBase",
    Alert = "Alert",
    HeightFilter = "HeightFilter",
    CreateTechnicalCheckin = "CreateTechnicalCheckin",
}

interface FlightsContainerComponentState {
    checkinAction: CheckinAction | undefined;
    isFormChanged: boolean;
    actionSidebarType: ActionSidebarType | undefined;
    zoom: { bounds: LatLngBounds } | undefined;
    contextMenuCoordinates: WGS84Coordinates | undefined;
    lastSelectedPoi: AlertPointOfInterest | undefined;
    displayDetailsId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-flights-container",
    templateUrl: "./flights-container.component.html",
    styleUrls: ["./flights-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightsContainerComponent {
    protected readonly isPanelProcessing$ = this.store.select(FlightsState.isPanelProcessing);
    protected readonly isActionProcessing$ = this.store.select(FlightsState.isActionProcessing);
    protected readonly flightList$ = this.store.select(FlightsState.filteredFlightList);
    protected readonly flightDetails$ = this.store.select(FlightsState.flightDetails);
    protected readonly hospitalList$ = this.store.select(AlertState.hospitalList);
    protected readonly hemsBaseList$ = this.store.select(AlertState.hemsBaseList);
    protected readonly zoneList$ = this.store.select(AlertState.zoneList);
    protected readonly selectedFlightId$ = this.store.select(FlightsState.selectedFlightId);
    protected readonly appliedFilters$ = this.store.select(FlightsState.appliedFilters);
    protected readonly isAtcController$ = this.store.select(FlightsState.isAtcController);
    protected readonly selectedTabType$ = this.store.select(FlightsState.selectedTabType);
    protected readonly selectedAlertGeometrySourceType$ = this.store.select(AlertState.selectedAlertGeometrySourceType);
    protected readonly isHeightFilterDialogOpen$ = this.store.select(FlightsState.isHeightFilterDialogOpen);
    protected readonly alerts$ = this.store.select(AlertState.alerts);
    protected readonly tabs$ = this.store.select(FlightsState.tabs);
    protected readonly jurisdictionMissions$ = this.store.select(FlightsState.jurisdictionMissions);
    protected readonly lastTechnicalCheckinData$ = this.store.select(FlightsState.lastTechnicalCheckinData);
    protected readonly isAlertActionProcessing$ = this.store.select(AlertState.isActionProcessing);
    protected readonly airspaceAutocompletionList$ = this.store.select(AlertState.airspaceAutocompletionList);
    protected readonly isAirspaceAutocompletionProcessing$ = this.store.select(AlertState.isAirspaceAutocompletionProcessing);
    protected readonly templates$ = this.store.select(AlertState.alertProposal).pipe(map((proposal) => proposal?.templates || []));
    protected readonly isManageAlertsFeatureAvailable$ = this.store.select(CapabilitiesState.isFeatureAvailable(DATSFeatures.ManageAlerts));
    protected readonly isMaintainFlightsFeatureAvailable$ = this.store.select(
        CapabilitiesState.isFeatureAvailable(DATSFeatures.MaintainFlights)
    );
    protected readonly isControlAirTrafficFeatureAvailable$ = this.store.select(
        CapabilitiesState.isFeatureAvailable(DATSFeatures.ControlAirTraffic)
    );
    protected readonly isTestSystemFeatureAvailable$ = this.store.select(CapabilitiesState.isFeatureAvailable(DATSFeatures.TestSystem));
    protected readonly technicalCheckinInitialFormData$ = combineLatest([
        this.lastTechnicalCheckinData$,
        this.localStore.selectByKey("contextMenuCoordinates"),
    ]).pipe(
        map<[TechnicalCheckinData | undefined, WGS84Coordinates | undefined], Partial<TechnicalCheckinData> | undefined>(
            ([lastTechnicalCheckinData, coordinates]) => {
                if (coordinates) {
                    return {
                        ...lastTechnicalCheckinData,
                        latitude: coordinates.latitude,
                        longitude: coordinates.longitude,
                    };
                }

                return lastTechnicalCheckinData;
            }
        )
    );
    protected readonly lastSelectedPoi$ = this.localStore.selectByKey("lastSelectedPoi");

    protected readonly ActionSidebarType = ActionSidebarType;
    protected readonly FlightAcceptancePhase = FlightAcceptancePhase;
    protected readonly displayDetailsId$ = this.localStore.selectByKey("displayDetailsId");
    protected readonly checkinAction$ = this.localStore.selectByKey("checkinAction").pipe(RxjsUtils.filterFalsy());
    protected readonly actionSidebarType$ = this.localStore.selectByKey("actionSidebarType");
    protected readonly isFormChanged$ = this.localStore.selectByKey("isFormChanged");
    protected readonly zoom$ = this.localStore.selectByKey("zoom");
    protected readonly heightFilter$ = this.store.select(FlightsState.heightFilter);
    protected readonly flight$ = this.localStore.selectByKey("checkinAction").pipe(
        RxjsUtils.filterFalsy(),
        map((checkinAction) => checkinAction.flight)
    );

    protected readonly ButtonTheme = ButtonTheme;
    protected readonly CheckinActionType = CheckinActionType;
    protected readonly AlertGeometrySourceType = AlertGeometrySourceType;
    protected readonly PoiType = PoiType;

    @ViewChild(FlightsPanelComponent) private readonly flightsPanelComponent: FlightsPanelComponent | undefined;
    @ViewChild(FlightsLayerDirective) private readonly flightsLayerDirective: FlightsLayerDirective | undefined;

    protected readonly mapOptions: MapOptions;

    constructor(
        private readonly errorHandlingService: ErrorHandlingService,
        private readonly localStore: LocalComponentStore<FlightsContainerComponentState>,
        private readonly store: Store,
        private readonly transloco: TranslocoService,
        private readonly dialogService: DialogService,
        @Inject(LEAFLET_MAP_CONFIG) leafletConfig: LeafletMapConfig
    ) {
        this.localStore.setState({
            checkinAction: undefined,
            isFormChanged: false,
            actionSidebarType: undefined,
            zoom: undefined,
            contextMenuCoordinates: undefined,
            lastSelectedPoi: undefined,
            displayDetailsId: undefined,
        });

        this.mapOptions = {
            center: leafletConfig.defaultPosition,
            zoom: leafletConfig.zoom.initial,
            minZoom: leafletConfig.zoom.min,
            maxZoom: leafletConfig.zoom.max,
            zoomControl: false,
            preferCanvas: true,
        };

        this.store.dispatch([
            new FlightsActions.GetFlights(),
            new FlightsActions.GetJurisdictionMissionList(),
            new AlertActions.GetAlerts(),
            new AlertActions.GetAllBookmarkedPois(),
        ]);

        this.synchronizeHeightFilterToActionSidebar();
        this.synchronizeAlertsToActionSidebarPanels();
        this.synchronizeCapabilitiesToMap();
    }

    protected updateSelectedTab(tab?: FlightsTab) {
        this.store.dispatch(new FlightsActions.SelectFlightsTab(tab?.type));
        this.localStore.patchState({ displayDetailsId: undefined });
    }

    protected markFormAsChanged(isFormChanged: boolean) {
        this.localStore.patchState({ isFormChanged });
    }

    protected synchronizeCapabilitiesToMap() {
        this.store
            .select(CapabilitiesState.jurisdiction)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.zoomToJurisdiction());
    }

    protected getAlertProposal(params: AlertProposalQueryParams) {
        this.store.dispatch(new AlertActions.GetAlertProposal(params));
    }

    protected synchronizeAlertsToActionSidebarPanels() {
        this.selectedAlertGeometrySourceType$
            .pipe(startWith(undefined), distinctUntilChanged(equal), pairwise(), untilDestroyed(this))
            .subscribe(async ([previous, current]: [AlertGeometrySourceType | undefined, AlertGeometrySourceType | undefined]) => {
                // NOTE: cancel alert form opening when something is already opened and user didn't confirm form closing
                if (
                    previous === undefined &&
                    current !== undefined &&
                    this.localStore.selectSnapshotByKey("actionSidebarType") !== undefined &&
                    !(await this.tryToCloseActionSidebar())
                ) {
                    this.store.dispatch(new AlertActions.SelectAlertGeometrySourceType(undefined));
                }

                // NOTE: open alert form when nothing is opened
                if (
                    previous === undefined &&
                    current !== undefined &&
                    this.localStore.selectSnapshotByKey("actionSidebarType") === undefined
                ) {
                    this.localStore.patchState({ actionSidebarType: ActionSidebarType.Alert });
                }
            });
    }

    protected synchronizeHeightFilterToActionSidebar() {
        this.isHeightFilterDialogOpen$
            .pipe(
                switchMap(async (isHeightFilterDialogOpen) => {
                    const currentSidebarType = this.localStore.selectSnapshotByKey("actionSidebarType");

                    if (!isHeightFilterDialogOpen && currentSidebarType === ActionSidebarType.HeightFilter) {
                        return this.localStore.patchState({ actionSidebarType: undefined });
                    }

                    if (isHeightFilterDialogOpen && currentSidebarType !== ActionSidebarType.HeightFilter) {
                        if (!(await this.tryToCloseActionSidebar())) {
                            this.store.dispatch(new FlightsActions.SetIsHeightFilterDialogOpen(false));

                            return;
                        }

                        return this.localStore.patchState({ actionSidebarType: ActionSidebarType.HeightFilter });
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();

        this.actionSidebarType$
            .pipe(
                filter((actionSidebarType) => actionSidebarType !== ActionSidebarType.HeightFilter),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.store.dispatch(new FlightsActions.SetIsHeightFilterDialogOpen(false));
            });
    }

    protected async confirmFormClose() {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("datsLibFlights.flightsContainer.dataNotSavedDialog.titleText"),
                confirmationText: this.transloco.translate("datsLibFlights.flightsContainer.dataNotSavedDialog.confirmationText"),
                declineButtonLabel: this.transloco.translate("datsLibFlights.flightsContainer.dataNotSavedDialog.declineButtonLabel"),
                confirmButtonLabel: this.transloco.translate("datsLibFlights.flightsContainer.dataNotSavedDialog.confirmationButtonLabel"),
                theme: ButtonTheme.Warn,
            },
        });

        return await firstValueFrom(dialogRef.afterClosed().pipe(map(Boolean), untilDestroyed(this))).catch(() => false);
    }

    protected async selectFlight(flightId: string | undefined, source: "map" | "list") {
        const checkinAction = this.localStore.selectSnapshotByKey("checkinAction");
        const actionSidebarType = this.localStore.selectSnapshotByKey("actionSidebarType");

        if (actionSidebarType === ActionSidebarType.Checkin && !(await this.tryToCloseActionSidebar())) {
            return;
        }

        if (actionSidebarType === ActionSidebarType.Checkin && checkinAction?.id !== flightId) {
            this.closeActionSidebar();
        }

        this.store.dispatch(new FlightsActions.SelectFlight(flightId));

        if (source === "map" && flightId) {
            this.flightsPanelComponent?.scrollFlightIntoView(flightId);
            if (this.localStore.selectSnapshotByKey("displayDetailsId")) {
                this.localStore.patchState({ displayDetailsId: flightId });
            }

            return;
        }

        if (source === "list" && flightId) {
            this.flightsLayerDirective?.zoomToFlightItem(
                flightId,
                this.store.selectSnapshot(FlightsState.selectedTabType) && this.flightsPanelComponent
                    ? this.flightsPanelComponent.clientWidth
                    : 0
            );
        }
    }

    protected changeAlertGeometrySourceType(alertGeometrySourceType: AlertGeometrySourceType | undefined) {
        this.store.dispatch(new AlertActions.SelectAlertGeometrySourceType(alertGeometrySourceType));

        if (alertGeometrySourceType === undefined) {
            this.localStore.patchState({
                actionSidebarType: undefined,
                isFormChanged: false,
                checkinAction: undefined,
                lastSelectedPoi: undefined,
            });
        }
    }

    protected async handleCheckinAction(checkinAction: CheckinAction) {
        this.selectFlight(checkinAction.id, "list");

        if (!(await this.tryToCloseActionSidebar())) {
            return;
        }

        this.localStore.patchState({ actionSidebarType: undefined });

        switch (checkinAction.type) {
            case CheckinActionType.Approve:
                this.store
                    .dispatch(new FlightsActions.AcceptCheckin(checkinAction.id))
                    .pipe(untilDestroyed(this))
                    .subscribe(() => {
                        const error = this.store.selectSnapshot(FlightsState.error);
                        if (error) {
                            this.errorHandlingService.displayMessage({
                                error,
                                errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.acceptanceErrorMessage"),
                            });
                        }
                    });
                break;

            case CheckinActionType.Stop:
                this.store
                    .dispatch(new FlightsActions.StopCheckin(checkinAction.id))
                    .pipe(untilDestroyed(this))
                    .subscribe(() => {
                        const error = this.store.selectSnapshot(FlightsState.error);
                        if (error) {
                            this.errorHandlingService.displayMessage({
                                error,
                                errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.stopErrorMessage"),
                            });
                        }
                    });
                break;

            case CheckinActionType.Standby:
                this.localStore.patchState({ checkinAction, actionSidebarType: ActionSidebarType.Checkin });
                break;

            case CheckinActionType.Modify:
                this.localStore.patchState({ checkinAction, actionSidebarType: ActionSidebarType.Checkin });
                break;

            case CheckinActionType.Archive:
                this.openArchiveDialog(checkinAction);

                break;
        }
    }

    protected openArchiveDialog(checkinAction: CheckinAction): void {
        this.dialogService
            .open(ArchiveDialogComponent)
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((formValues) => this.store.dispatch(new FlightsActions.ArchiveCheckin(checkinAction.id, formValues))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);
                if (error) {
                    this.errorHandlingService.displayMessage({
                        error,
                        errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.archiveErrorMessage"),
                    });
                }
            });
    }

    protected async tryToCloseActionSidebar() {
        const isFormChanged = this.localStore.selectSnapshotByKey("isFormChanged");

        if (isFormChanged && !(await this.confirmFormClose())) {
            return false;
        }

        this.closeActionSidebar();

        return true;
    }

    protected async closeActionSidebar() {
        const actionSidebarType = this.localStore.selectSnapshotByKey("actionSidebarType");

        if (actionSidebarType === ActionSidebarType.Alert) {
            this.store.dispatch(new AlertActions.SelectAlertGeometrySourceType(undefined));
        }

        this.localStore.patchState({ actionSidebarType: undefined, isFormChanged: false, checkinAction: undefined });
    }

    protected manageHospitalListPanel(isOpen: boolean): void {
        this.localStore.patchState({ actionSidebarType: isOpen ? ActionSidebarType.Hospital : undefined });
    }

    protected manageHemsBaseListPanel(isOpen: boolean): void {
        this.localStore.patchState({ actionSidebarType: isOpen ? ActionSidebarType.HemsBase : undefined });
    }

    protected openAlertForm(poi: AlertPointOfInterest): void {
        let alertGeometrySourceType: AlertGeometrySourceType;

        switch (poi.type) {
            case PoiType.Hospital:
                alertGeometrySourceType = AlertGeometrySourceType.Hospital;
                break;
            case PoiType.Hems:
                alertGeometrySourceType = AlertGeometrySourceType.HEMS;
                break;
            default:
                return;
        }

        this.localStore.patchState({ lastSelectedPoi: poi });
        this.store.dispatch(new AlertActions.SelectAlertGeometrySourceType(alertGeometrySourceType));
    }

    protected dispatchHospitalList(hospitals: AlertPointOfInterest[]): void {
        this.store
            .dispatch(new AlertActions.SetBookmarkedHospitals(hospitals))
            .pipe(
                switchMap(() => {
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error) {
                        this.errorHandlingService.displayMessage({
                            error,
                            errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.bookmarkHospitalErrorMessage"),
                        });

                        return EMPTY;
                    }

                    return this.store.dispatch(new AlertActions.GetAllHospitals());
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.manageHospitalListPanel(false);
            });
    }

    protected dispatchHemsBaseList(hemsBases: AlertPointOfInterest[]): void {
        this.store
            .dispatch(new AlertActions.SetBookmarkedHemsBases(hemsBases))
            .pipe(
                switchMap(() => {
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error) {
                        this.errorHandlingService.displayMessage({
                            error,
                            errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.bookmarkHemsBaseErrorMessage"),
                        });

                        return EMPTY;
                    }

                    return this.store.dispatch(new AlertActions.GetAllHemsBases());
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.manageHemsBaseListPanel(false);
            });
    }

    protected delayOrRejectCheckin(event: { id: string; duration: number; isTimeExceeded: boolean }): void {
        if (event.isTimeExceeded) {
            this.store
                .dispatch(new FlightsActions.RejectCheckin(event.id))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error) {
                        this.errorHandlingService.displayMessage({
                            error,
                            errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.rejectCheckinErrorMessage"),
                        });
                    }
                });

            return;
        }

        this.store
            .dispatch(new FlightsActions.StandbyCheckin(event.id, event.duration))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.errorHandlingService.displayMessage({
                        error,
                        errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.rejectCheckinErrorMessage"),
                    });
                }
            });
    }

    protected modifyCheckin({ flight, formValues }: { flight: FlightItem; formValues: ModifyCheckinFormValues }): void {
        const action =
            flight.acceptance.phase === FlightAcceptancePhase.Pending
                ? new FlightsActions.ModifyCheckinAndAccept(flight.id, formValues)
                : new FlightsActions.ModifyCheckin(flight.id, formValues);

        this.store
            .dispatch(action)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.errorHandlingService.displayMessage({
                        error,
                        errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.acceptanceErrorMessage"),
                    });
                }
            });
    }

    protected resendConfirmation(id: string): void {
        this.store
            .dispatch(new FlightsActions.ResendCheckinConfirmation(id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error) {
                    this.errorHandlingService.displayMessage({
                        error,
                        errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.resendConfirmationErrorMessage"),
                    });
                }
            });
    }

    protected applyFilters(filters: FlightFilterType[]): void {
        this.store.dispatch(new FlightsActions.ApplyFilters(filters));
    }

    protected zoomToJurisdiction() {
        const bounds = this.store.selectSnapshot(CapabilitiesState.jurisdiction)?.bounds;
        this.localStore.patchState({
            zoom: bounds ? { bounds } : undefined,
        });
    }

    protected createTechnicalCheckin(formData: TechnicalCheckinData) {
        this.store
            .dispatch(new FlightsActions.CreateTechnicalCheckin(formData))
            .pipe(
                switchMap(() => {
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error && error.type === FlightsErrorType.CannotCreateTechnicalCheckin) {
                        this.errorHandlingService.displayMessage({
                            error,
                            errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.createTechnicalCheckinErrorMessage"),
                        });
                    } else {
                        this.closeActionSidebar();
                    }

                    return EMPTY;
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected async openTechnicalCheckinForm(coordinates: WGS84Coordinates | undefined) {
        if (!(await this.tryToCloseActionSidebar())) {
            return;
        }

        this.localStore.patchState({ contextMenuCoordinates: coordinates, actionSidebarType: ActionSidebarType.CreateTechnicalCheckin });
    }

    protected loadExtendedMissionData(missionId: string) {
        this.store
            .dispatch(new FlightsActions.GetJurisdictionMission(missionId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightsState.error);

                if (error && error.type === FlightsErrorType.CannotGetJurisdictionMission) {
                    this.errorHandlingService.displayMessage({
                        error,
                        errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.getJurisdictionMissionErrorMessage"),
                    });
                }
            });
    }

    protected createAlert(formData: AirspaceAlertFormGroupValue | HospitalAlertFormGroupValue | HemsAlertFormGroupValue) {
        const alertData: ZoneAlertEntity | PoiAlertEntity =
            formData.source === AlertGeometrySourceType.Airspace
                ? ({
                      type: formData.type,
                      area: {
                          "@type": AlertAreaType.Zone,
                          designator: formData.zone.designator,
                      },
                      startAt: formData.timeRange?.startDate ?? new Date(),
                      duration: formData.timeRange?.duration ?? undefined,
                      message: {
                          content: formData.message,
                          templateId: formData.messageTemplateId,
                          isModified: formData.isMessageModified,
                      },
                  } satisfies ZoneAlertEntity)
                : ({
                      type: formData.type,
                      area: {
                          "@type": AlertAreaType.Poi,
                          id: formData.source === AlertGeometrySourceType.Hospital ? formData.hospital.id : formData.hemsBase.id,
                      },
                      startAt: formData.timeRange?.startDate ?? new Date(),
                      duration: formData.timeRange?.duration ?? undefined,
                      message: {
                          content: formData.message,
                          templateId: formData.messageTemplateId,
                          isModified: formData.isMessageModified,
                      },
                  } satisfies PoiAlertEntity);

        this.store
            .dispatch(new AlertActions.CreateAlert(alertData))
            .pipe(
                switchMap(() => {
                    const error = this.store.selectSnapshot(AlertState.error);

                    if (error && error.type === AlertErrorType.CannotGetPointsOfInterest) {
                        this.errorHandlingService.displayMessage({
                            error,
                            errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.createAlertErrorMessage"),
                        });
                    } else {
                        this.closeActionSidebar();
                    }

                    return EMPTY;
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected updateHeightFilter(height: number) {
        this.store.dispatch(new FlightsActions.SetHeightFilter(height));
        this.closeActionSidebar();
    }

    protected handleDetailsDisplay(id: string | undefined) {
        this.localStore.patchState({ displayDetailsId: id });

        if (id) {
            this.store
                .dispatch(new FlightsActions.GetFlightDetails(id))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const error = this.store.selectSnapshot(FlightsState.error);

                    if (error && error.type === FlightsErrorType.CannotGetFlightDetails) {
                        this.errorHandlingService.displayMessage({
                            error,
                            errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.getFlightDetailsErrorMessage"),
                        });

                        return;
                    }
                });

            this.selectFlight(id, "list");
        }
        const selectedFlightId = this.store.selectSnapshot(FlightsState.selectedFlightId);

        if (!id && selectedFlightId) {
            // NOTE: Scrolling should be done after the flight list is rendered
            setTimeout(() => {
                this.flightsPanelComponent?.scrollFlightIntoView(selectedFlightId);
            }, 0);
        }
    }

    protected searchZones(value: string) {
        this.store.dispatch(new AlertActions.GetAirspaceAutocompletion(value)).subscribe(() => {
            const error = this.store.selectSnapshot(AlertState.error);

            if (error && error.type === AlertErrorType.CannotGetAirspaceAutocompletion) {
                this.errorHandlingService.displayMessage({
                    error,
                    errorMessage: this.transloco.translate("datsLibFlights.flightsContainer.getAirspaceAutocompletionErrorMessage"),
                });
            }
        });
    }
}
