import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { SYSTEM_TRANSLATION_SCOPE, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoService } from "@jsverse/transloco";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { Error } from "../models/error.model";

const STATUS_TO_MESSAGE: Partial<Record<number, string>> = {
    0: "datsLibShared.errorHandling.unknownErrorMessage",
    400: "datsLibShared.errorHandling.badRequestErrorMessage",
    401: "datsLibShared.errorHandling.unauthorizedErrorMessage",
    404: "datsLibShared.errorHandling.notFoundErrorMessage",
    409: "datsLibShared.errorHandling.conflictErrorMessage",
    422: "datsLibShared.errorHandling.unprocessableEntityErrorMessage",
    429: "datsLibShared.errorHandling.tooManyRequestsErrorMessage",
    500: "datsLibShared.errorHandling.internalServerErrorErrorMessage",
    503: "datsLibShared.errorHandling.serviceUnavailableErrorMessage",
    505: "datsLibShared.errorHandling.notImplementedErrorMessage",
};

const STATUS_FAMILY_TO_MESSAGE: Partial<Record<number, string>> = {
    4: "datsLibShared.errorHandling.clientErrorMessage", // NOTE: family 4xx
    5: "datsLibShared.errorHandling.serverErrorMessage", // NOTE: family 5xx
};

export interface ErrorToDisplay {
    error: Error<unknown>;
    errorMessage?: string;
    errorMessagePerStatus?: Partial<Record<number, string>>;
    options?: Partial<IndividualConfig>;
    fieldErrorNames?: Partial<Record<string, string>>;
}

const DEFAULT_ERROR_MESSAGE_PER_STATUS: Partial<Record<number, string>> = {
    0: STATUS_TO_MESSAGE[0],
    [HttpStatusCode.TooManyRequests]: STATUS_TO_MESSAGE[HttpStatusCode.TooManyRequests],
};

const DEFAULT_OPTIONS: Partial<IndividualConfig> = {
    enableHtml: true,
};

@Injectable({
    providedIn: "root",
})
export class ErrorHandlingService {
    private readonly toastrService = inject(ToastrService);
    private readonly translationHelperService = inject(TranslationHelperService);
    private readonly transloco = inject(TranslocoService);

    public async displayMessage({ error, errorMessage, errorMessagePerStatus, options, fieldErrorNames }: ErrorToDisplay) {
        const httpErrorResponse = error?.error;
        const toastrMessage = this.getErrorMessage(httpErrorResponse, errorMessage, {
            ...DEFAULT_ERROR_MESSAGE_PER_STATUS,
            ...errorMessagePerStatus,
        });

        const toastrMessageWithStatusSuffix = this.getToastrMessageWithStatusSuffix(httpErrorResponse?.status, toastrMessage);
        const toastrMessageWithFieldErrors = this.getToastrMessageWithFieldErrors(
            toastrMessageWithStatusSuffix,
            httpErrorResponse?.error?.fieldErrors,
            fieldErrorNames
        );
        if (toastrMessage) {
            this.toastrService.error(toastrMessageWithFieldErrors, undefined, { ...DEFAULT_OPTIONS, ...options });
        }
    }

    private getErrorMessage(
        errorResponse: HttpErrorResponse,
        errorMessage?: string,
        errorMessagePerStatus?: Partial<Record<number, string>>
    ): string | undefined {
        return (
            this.getSystemMessage(errorResponse) ??
            this.getCustomStatusMessage(errorResponse, errorMessagePerStatus) ??
            this.getGeneralMessage(errorMessage, errorResponse) ??
            this.getDefaultStatusMessage(errorResponse) ??
            this.getStatusMessageByFamily(errorResponse.status) ??
            this.transloco.translate("datsLibShared.errorHandling.unknownErrorMessage")
        );
    }

    private getSystemMessage(errorResponse?: HttpErrorResponse): string | undefined {
        if (!errorResponse?.error?.generalMessage) {
            return undefined;
        }

        const systemMessage = this.translationHelperService.selectSystemTranslation(
            errorResponse.error.generalMessage,
            errorResponse.error.args
        );

        if (systemMessage !== `${SYSTEM_TRANSLATION_SCOPE}.${errorResponse.error.generalMessage}`) {
            return systemMessage;
        }

        return undefined;
    }

    private getCustomStatusMessage(
        errorResponse?: HttpErrorResponse,
        errorMessagePerStatus?: Partial<Record<number, string>>
    ): string | undefined {
        if (errorMessagePerStatus && errorResponse?.status !== undefined) {
            const errorMessagePerStatusKey = errorMessagePerStatus[errorResponse?.status];
            if (errorMessagePerStatusKey) {
                return this.transloco.translate(errorMessagePerStatusKey);
            }
        }

        return undefined;
    }

    private getGeneralMessage(errorMessage: string | undefined, errorResponse: HttpErrorResponse | undefined): string | undefined {
        if (errorMessage && errorResponse?.status === HttpStatusCode.InternalServerError) {
            return errorMessage;
        }

        return undefined;
    }

    private getDefaultStatusMessage(errorResponse?: HttpErrorResponse): string | undefined {
        if (!errorResponse?.status) {
            return undefined;
        }

        const message = STATUS_TO_MESSAGE[errorResponse.status];
        if (message) {
            return this.transloco.translate(message);
        }

        return undefined;
    }

    private getStatusMessageByFamily(status: number): string | undefined {
        const statusFamily = Math.floor(status / 100);
        const statusMessageByFamily = STATUS_FAMILY_TO_MESSAGE[statusFamily];
        if (statusMessageByFamily) {
            return this.transloco.translate(statusMessageByFamily);
        }

        return undefined;
    }

    private getToastrMessageWithStatusSuffix(status?: number, message?: string): string | undefined {
        if (status === undefined) {
            return message;
        }

        return `${message} [${status}]`;
    }

    private getToastrMessageWithFieldErrors(
        message?: string,
        fieldErrors?: { fieldName: string; message: string; args: { [key: string]: string } }[],
        fieldErrorNames?: Partial<Record<string, string>>
    ): string | undefined {
        if (!fieldErrors) {
            return message;
        }

        const fieldErrorsString = fieldErrors
            .map((fieldError) => {
                const fieldErrorTranslation = this.translationHelperService.selectSystemTranslation(fieldError.message, fieldError.args);

                if (fieldErrorTranslation !== `${SYSTEM_TRANSLATION_SCOPE}.${fieldError.message}`) {
                    return `<li>${fieldErrorNames?.[fieldError.fieldName] || fieldError.fieldName}: ${fieldErrorTranslation}</li>`;
                }

                return "";
            })
            .join("");

        if (fieldErrorsString.length === 0) {
            return message;
        }

        return `${message}<ul>${fieldErrorsString}</ul>`;
    }
}
