<dats-lib-action-sidebar-header (actionCancel)="actionCancel.emit()">
    {{ "datsLibFlights.heightFilterForm.actionSidebarHeader" | transloco }}
</dats-lib-action-sidebar-header>
<div class="action-sidebar-content scroll-shadows">
    <button
        type="button"
        class="selectable-tile"
        (click)="setHeightValue(0)"
        [class.selected]="heightFilterForm.controls.height.value === 0"
    >
        {{ "datsLibFlights.heightFilterForm.allButtonLabel" | transloco }}
    </button>
    <section class="section">
        <dtm-ui-label-value [label]="'datsLibFlights.heightFilterForm.heightLabel' | transloco"></dtm-ui-label-value>
        <section class="modify-height">
            <!-- TODO: DTM-5197 - Replace ngx-slider after create custom slider component -->
            <ngx-slider
                (userChangeEnd)="heightFilterForm.controls.customHeight.setValue($event.value)"
                [formControl]="heightFilterForm.controls.height"
                class="dtm-slider-vertical-height"
                [options]="HEIGHT_SLIDER_OPTIONS"
            ></ngx-slider>
            <div class="custom-value-tiles">
                <div class="predefined-values">
                    <button
                        type="button"
                        class="selectable-tile"
                        tabindex="0"
                        *ngFor="let value of PREDEFINED_HEIGHT_VALUES"
                        (click)="setHeightValue(value)"
                    >
                        {{ value }}
                    </button>
                </div>
                <dtm-ui-expanded-number-input [formControl]="heightFilterForm.controls.customHeight" [minValue]="0" [stepValue]="10">
                </dtm-ui-expanded-number-input>
            </div>
        </section>
    </section>
</div>
<dats-lib-action-sidebar-footer (actionCancel)="actionCancel.emit()" (actionConfirm)="confirm()">
    <button *ngIf="isFormChanged$ | ngrxPush" type="button" class="button-secondary" (click)="restoreInitialData()">
        <dtm-ui-icon name="refresh"></dtm-ui-icon>
        {{ "datsLibFlights.actionSidebar.restoreButtonLabel" | transloco }}
    </button>
</dats-lib-action-sidebar-footer>
