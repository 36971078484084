import { AlertGeometrySourceType, AlertPointOfInterest, AlertProposalQueryParams, LocationAlertEntity } from "../models/alert.models";

export namespace AlertActions {
    export class SelectAlertGeometrySourceType {
        public static readonly type = "[Alert] Select alert type";
        constructor(public type: AlertGeometrySourceType | undefined) {}
    }

    export class GetAlerts {
        public static readonly type = "[Alert] Get alerts";
    }

    export class GetAlertProposal {
        public static readonly type = "[Alert] Get alert proposal";
        constructor(public params: AlertProposalQueryParams) {}
    }

    export class CreateAlert {
        public static readonly type = "[Alert] Create alert";
        constructor(public alertEntity: LocationAlertEntity) {}
    }

    export class GetAllHospitals {
        public static readonly type = "[Alert] Get all hospitals";
    }

    export class GetAllHemsBases {
        public static readonly type = "[Alert] Get all HEMS bases";
    }

    export class GetAllZones {
        public static readonly type = "[Alert] Get all zones";
    }

    export class GetAllBookmarkedPois {
        public static readonly type = "[Alert] Get all bookmarked POIs";
    }

    export class SetBookmarkedHospitals {
        public static readonly type = "[Alert] Set bookmarked hospitals";
        constructor(public newBookmarkedHospitals: AlertPointOfInterest[]) {}
    }

    export class GetAirspaceAutocompletion {
        public static readonly type = "[Alert] Get airspace autocompletion";
        constructor(public value: string) {}
    }

    export class SetBookmarkedHemsBases {
        public static readonly type = "[Alert] Set bookmarked hems bases";
        constructor(public newBookmarkedHemsBases: AlertPointOfInterest[]) {}
    }
}
