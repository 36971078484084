<ng-container *ngrxLet="{ connectionStatus: connectionStatus$ } as vm">
    <dtm-ui-icon
        name="status-online-offline"
        class="status"
        [class.online]="vm.connectionStatus === WebsocketConnectionStatus.Connected"
        [class.offline]="vm.connectionStatus === WebsocketConnectionStatus.Disconnected"
        [class.reconnecting]="
            vm.connectionStatus === WebsocketConnectionStatus.Connecting || vm.connectionStatus === WebsocketConnectionStatus.Disconnecting
        "
    ></dtm-ui-icon>
</ng-container>
