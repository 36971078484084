<ng-container *ngrxLet="{ hemsBases: hemsBases$, templates: templates$, selectedType: selectedType$ } as vm">
    <dats-lib-action-sidebar-header (actionCancel)="actionCancel.emit()">
        {{ "datsLibAlert.alertForms.hemsAlertForm.header" | transloco }}
    </dats-lib-action-sidebar-header>
    <dats-lib-alert-form
        class="action-sidebar-content scroll-shadows"
        [templates]="vm.templates"
        [isMessageTemplateIdSelectionDisabled]="!hemsBaseFormControl.value"
    >
        <dtm-ui-select-field
            contentSlot
            [placeholder]="'datsLibAlert.alertForms.selectPlaceholder' | transloco"
            [formControl]="hemsBaseFormControl"
            [isClearable]="false"
        >
            <label>{{ "datsLibAlert.alertForms.hemsAlertForm.hemsBaseLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let hemsBase of vm.hemsBases" [value]="hemsBase">
                {{ hemsBase.title }}
                <span hint *ngIf="hemsBase.description">{{ hemsBase.description }}</span>
            </dtm-ui-select-option>

            <div class="field-error" *dtmUiFieldHasError="hemsBaseFormControl; name: 'required'">
                {{ "datsLibAlert.requiredFieldErrorMessage" | transloco }}
            </div>
        </dtm-ui-select-field>
    </dats-lib-alert-form>
    <dats-lib-action-sidebar-footer
        (actionCancel)="actionCancel.emit()"
        (actionConfirm)="tryEmitConfirm()"
        [confirmButtonText]="'datsLibAlert.alertForms.saveButtonLabel' | transloco : { value: vm.selectedType }"
    >
    </dats-lib-action-sidebar-footer>
</ng-container>
