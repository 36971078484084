<ng-container
    *ngrxLet="{
        visibleBookmarkedList: visibleBookmarkedList$,
        hospitalList: hospitalList$,
        hemsBaseList: hemsBaseList$
    } as vm"
>
    <div class="poi-action" [matTooltip]="'datsLibFlights.pointOfInterest.searchTooltip' | transloco" matTooltipPosition="left">
        <dtm-ui-icon name="search"></dtm-ui-icon>
    </div>
    <section class="poi-section">
        <div
            class="poi-action"
            [matTooltip]="'datsLibFlights.pointOfInterest.hospitalsTooltip' | transloco"
            matTooltipPosition="left"
            (click)="displayBookmarkedHospitals()"
        >
            <dtm-ui-icon name="first-aid-kit-line"></dtm-ui-icon>
        </div>
        <div class="list-wrapper" *ngIf="vm.visibleBookmarkedList === VisibleBookmarkedListType.Hospitals">
            <div class="bookmarked-poi-list">
                <div *ngFor="let hospital of vm.hospitalList" class="element" (click)="selectPoi(hospital)" [matTooltip]="hospital.title">
                    {{ hospital.title | ellipsifyText : 15 }}
                </div>
            </div>
            <div
                class="element bookmarked"
                (click)="favoriteHospitalsClick.emit()"
                [matTooltip]="'datsLibFlights.pointOfInterest.addFavoriteHospitalsTooltip' | transloco"
            >
                <dtm-ui-icon name="star-fill"></dtm-ui-icon>
            </div>
        </div>
    </section>
    <section class="poi-section">
        <div
            class="poi-action"
            [matTooltip]="'datsLibFlights.pointOfInterest.hemsBasesTooltip' | transloco"
            matTooltipPosition="left"
            (click)="displayBookmarkedHemsBases()"
        >
            <dtm-ui-icon name="complex-helicopter"></dtm-ui-icon>
        </div>
        <div class="list-wrapper" *ngIf="vm.visibleBookmarkedList === VisibleBookmarkedListType.HemsBases">
            <div class="bookmarked-poi-list">
                <div *ngFor="let hemsBase of vm.hemsBaseList" class="element" (click)="selectPoi(hemsBase)" [matTooltip]="hemsBase.title">
                    {{ hemsBase.title | ellipsifyText : 15 }}
                </div>
            </div>
            <div
                class="element bookmarked"
                (click)="favoriteHemsBasesClick.emit()"
                [matTooltip]="'datsLibFlights.pointOfInterest.addFavoriteHemsBasesTooltip' | transloco"
            >
                <dtm-ui-icon name="star-fill"></dtm-ui-icon>
            </div>
        </div>
    </section>

    <!-- TODO: DTATS-421 Enable when ready
        <div class="poi-action" [matTooltip]="'datsLibFlights.pointOfInterest.zonesTooltip' | transloco" matTooltipPosition="left">
        <dtm-ui-icon name="collage"></dtm-ui-icon>
    </div> -->
</ng-container>
