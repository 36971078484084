import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetDirective } from "@ngrx/component";
import { DecimalToDmsCoordinatesPipe } from "../../../../shared/pipes/decimal-to-dms-coordinates.pipe";
import { FlightOperation } from "../../../models/flight.models";

interface FlightDetailsConflictZonesComponentState {
    flightOperation: FlightOperation | undefined;
}

@Component({
    selector: "dats-lib-flight-details-conflict-zones",
    standalone: true,
    imports: [CommonModule, LetDirective, SharedUiModule, TranslocoModule, DecimalToDmsCoordinatesPipe],
    templateUrl: "./flight-details-conflict-zones.component.html",
    styleUrls: ["./flight-details-conflict-zones.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightDetailsConflictZonesComponent {
    @Input() public set flightOperation(value: FlightOperation | undefined) {
        this.localStore.patchState({ flightOperation: value });
    }

    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly flightOperation$ = this.localStore.selectByKey("flightOperation");

    constructor(private readonly localStore: LocalComponentStore<FlightDetailsConflictZonesComponentState>) {
        this.localStore.setState({
            flightOperation: undefined,
        });
    }
}
