<ng-container
    *ngrxLet="{
        flightDetails: flightDetails$,
        isMaintainFlightsFeatureAvailable: isMaintainFlightsFeatureAvailable$,
        isControlAirTrafficFeatureAvailable: isControlAirTrafficFeatureAvailable$
    } as vm"
>
    <dats-lib-flight-item
        [flight]="vm.flightDetails?.flight?.flightItem"
        [category]="vm.flightDetails?.flight?.flightItem?.category"
        [isMaintainFlightsFeatureAvailable]="vm.isMaintainFlightsFeatureAvailable"
        [isControlAirTrafficFeatureAvailable]="vm.isControlAirTrafficFeatureAvailable"
        (checkinModify)="checkinModify.emit($event)"
    ></dats-lib-flight-item>

    <dtm-ui-expandable-panel *ngIf="vm.flightDetails?.flight?.flightItem?.messageToAnsp" [hasHeaderSeparator]="false">
        <div headerSlot>{{ "datsLibFlights.flightDetails.informationFromPilotLabel" | transloco }}</div>
        {{ vm.flightDetails?.flight?.flightItem?.messageToAnsp }}
    </dtm-ui-expandable-panel>

    <dats-lib-flight-details-conflict-zones
        *ngIf="vm.flightDetails?.flight?.flightItem?.operation"
        [flightOperation]="vm.flightDetails?.flight?.flightItem?.operation"
    ></dats-lib-flight-details-conflict-zones>

    <ng-container *ngIf="vm.flightDetails?.mission">
        <h2 class="mission-details-title">{{ "datsLibFlights.missionDetails.titleLabel" | transloco }}</h2>
        <dats-lib-mission-details [flightDetails]="vm.flightDetails"></dats-lib-mission-details>
    </ng-container>
</ng-container>
