<ng-container
    *ngrxLet="{
        isAtcController: isAtcController$,
        isAlertSelectButtonDisabled: isAlertSelectButtonDisabled$,
        isManageAlertsFeatureAvailable: isManageAlertsFeatureAvailable$,
        heightFilter: heightFilter$,
        connectionStatus: connectionStatus$
    } as vm"
>
    <div class="block">
        <dats-lib-flights-navbar-connection-status [connectionStatus]="vm.connectionStatus" />
        <dats-lib-flights-navbar-clock />
    </div>
    <div class="block">
        <button type="button" (click)="openHeightFilterDialog()" [class.active]="vm.heightFilter">
            {{ "datsLibFlights.heightFilterForm.heightFilterButtonLabel" | transloco : { value: vm.heightFilter } }}
            <dtm-ui-icon name="edit-fill" />
        </button>
    </div>
    <div class="block" *ngIf="vm.isManageAlertsFeatureAvailable">
        <dats-lib-alert-select-button
            [isDisabled]="vm.isAlertSelectButtonDisabled"
            (alertGeometrySourceTypeChange)="changeAlertGeometrySourceType($event)"
        />
    </div>
</ng-container>
