import { ArchiveCheckinFormValues, FlightsTabType, ModifyCheckinFormValues, TechnicalCheckinData } from "../models/flight.models";
import { FlightFilterType } from "../models/flights-filters.models";

export namespace FlightsActions {
    export class StandbyCheckin {
        public static readonly type = "[Flights] Standby checkin";
        constructor(public checkinId: string, public duration: number) {}
    }

    export class ModifyCheckin {
        public static readonly type = "[Flights] Modify checkin";
        constructor(public checkinId: string, public formValues: ModifyCheckinFormValues) {}
    }

    export class ModifyCheckinAndAccept {
        public static readonly type = "[Flights] Modify checkin and accept";
        constructor(public checkinId: string, public formValues: ModifyCheckinFormValues) {}
    }

    export class AcceptCheckin {
        public static readonly type = "[Flights] Accept checkin";
        constructor(public checkinId: string) {}
    }

    export class RejectCheckin {
        public static readonly type = "[Flights] Reject checkin";
        constructor(public checkinId: string) {}
    }

    export class StopCheckin {
        public static readonly type = "[Flights] Stop checkin";
        constructor(public checkinId: string) {}
    }

    export class ArchiveCheckin {
        public static readonly type = "[Flights] Archive checkin";
        constructor(public checkinId: string, public formValues: ArchiveCheckinFormValues) {}
    }

    export class ResendCheckinConfirmation {
        public static readonly type = "[Flights] Resend checkin confirmation";
        constructor(public checkinId: string) {}
    }

    export class GetFlights {
        public static readonly type = "[Flights] Get flights";
    }

    export class GetFlightDetails {
        public static readonly type = "[Flights] Get flight details";
        constructor(public flightId: string) {}
    }

    export class SelectFlightsTab {
        public static readonly type = "[Flights] Select flights tab";
        constructor(public tabType: FlightsTabType | undefined) {}
    }

    export class ClearCategorizedFlights {
        public static readonly type = "[Flights] Clear categorized flights";
    }

    export class SelectFlight {
        public static readonly type = "[Flights] Select flight";
        constructor(public flightId: string | undefined) {}
    }

    export class ApplyFilters {
        public static readonly type = "[Flights] Apply filters";
        constructor(public filters: FlightFilterType[]) {}
    }

    export class GetJurisdictionMissionList {
        public static readonly type = "[Flights] Get jurisdiction mission list";
    }

    export class GetJurisdictionMission {
        public static readonly type = "[Flights] Get jurisdiction mission";
        constructor(public id: string) {}
    }

    export class SetIsHeightFilterDialogOpen {
        public static readonly type = "[Flights] Set height filter dialog";
        constructor(public isOpen: boolean) {}
    }

    export class SetHeightFilter {
        public static readonly type = "[Flights] Set height filter";
        constructor(public height: number) {}
    }

    export class CreateTechnicalCheckin {
        public static readonly type = "[Flights] Create technical checkin";
        constructor(public checkinData: TechnicalCheckinData) {}
    }

    export class WatchFlights {
        public static readonly type = "[Flights] Watch flights Websocket";
    }
}
