<dats-lib-expandable-panel
    *ngrxLet="{
        flights: flights$,
        category: category$,
        isExpanded: isExpanded$,
        totalFlights: total$,
        isAtcController: isAtcController$,
        filteredFlights: filteredFlights$,
        selectedFlightId: selectedFlightId$,
        isMaintainFlightsFeatureAvailable: isMaintainFlightsFeatureAvailable$,
        isControlAirTrafficFeatureAvailable: isControlAirTrafficFeatureAvailable$,
    } as vm"
    [isExpanded]="vm.isExpanded"
    [isDisabled]="vm.totalFlights === 0"
    (expandChange)="expandChange.emit($event)"
>
    <span headerSlot>
        {{
            "datsLibFlights.categoryFlightList.headerLabel"
                | transloco
                    : {
                          category: vm.category
                      }
        }}
        <span class="total-flights">{{ vm.totalFlights }}</span>
    </span>
    <div class="flight-list">
        <dats-lib-flight-item
            *ngFor="let flight of vm.filteredFlights"
            [flight]="flight"
            [category]="vm.category"
            [isAtcController]="vm.isAtcController"
            [isSelected]="vm.selectedFlightId === flight.id"
            [isMaintainFlightsFeatureAvailable]="vm.isMaintainFlightsFeatureAvailable"
            [isControlAirTrafficFeatureAvailable]="vm.isControlAirTrafficFeatureAvailable"
            (confirmationResend)="confirmationResend.emit($event)"
            (checkinModify)="checkinModify.emit($event)"
            (selectionChanged)="changeSelection($event, flight)"
            (displayDetailsIdChanged)="displayDetailsIdChanged.emit($event)"
            [isClickable]="true"
            [isDetailsButtonVisible]="true"
        ></dats-lib-flight-item>
    </div>
</dats-lib-expandable-panel>
