import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { LetDirective } from "@ngrx/component";
import { FlightDetails } from "../../models/flight.models";
import { MissionDetailsArchiveReasonComponent } from "./mission-details-archive-reason/mission-details-archive-reason.component";
import { MissionDetailsBasicDataComponent } from "./mission-details-basic-data/mission-details-basic-data.component";
import { MissionDetailsOperatorComponent } from "./mission-details-operator/mission-details-operator.component";
import { MissionDetailsPilotComponent } from "./mission-details-pilot/mission-details-pilot.component";
import { MissionDetailsRejectReasonComponent } from "./mission-details-reject-reason/mission-details-reject-reason.component";
import { MissionDetailsUavComponent } from "./mission-details-uav/mission-details-uav.component";

interface MissionDetailsComponentState {
    flightDetails: FlightDetails | undefined;
}

@Component({
    selector: "dats-lib-mission-details",
    standalone: true,
    imports: [
        CommonModule,
        LetDirective,
        MissionDetailsBasicDataComponent,
        MissionDetailsPilotComponent,
        MissionDetailsOperatorComponent,
        MissionDetailsUavComponent,
        MissionDetailsArchiveReasonComponent,
        MissionDetailsRejectReasonComponent,
    ],
    templateUrl: "./mission-details.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDetailsComponent {
    @Input({ required: true }) public set flightDetails(value: FlightDetails | undefined) {
        this.localStore.patchState({ flightDetails: value });
    }

    protected readonly flightDetails$ = this.localStore.selectByKey("flightDetails");

    constructor(private readonly localStore: LocalComponentStore<MissionDetailsComponentState>) {
        this.localStore.setState({
            flightDetails: undefined,
        });
    }
}
