import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { PushPipe } from "@ngrx/component";

interface MissionDetailsArchiveReasonComponentState {
    archiveReason: string | undefined;
}

@Component({
    selector: "dats-lib-mission-details-archive-reason",
    standalone: true,
    imports: [CommonModule, PushPipe, SharedUiModule, SharedI18nModule, TranslocoModule],
    templateUrl: "./mission-details-archive-reason.component.html",
    styleUrls: ["./mission-details-archive-reason.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDetailsArchiveReasonComponent {
    @Input() public set archiveReason(value: string | undefined) {
        this.localStore.patchState({ archiveReason: value });
    }

    protected readonly archiveReason$ = this.localStore.selectByKey("archiveReason");

    constructor(private readonly localStore: LocalComponentStore<MissionDetailsArchiveReasonComponentState>) {
        this.localStore.setState({
            archiveReason: undefined,
        });
    }
}
