import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { LetDirective } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { map } from "rxjs";
import { AlertErrorType, AlertPointOfInterest } from "../../../../alert/models/alert.models";
import { AlertActions } from "../../../../alert/state/alert.actions";
import { AlertState } from "../../../../alert/state/alert.state";
import { ErrorHandlingService } from "../../../../shared/services/error-handling-service";

enum VisibleBookmarkedListType {
    Hospitals = "Hospitals",
    HemsBases = "HemsBases",
}

interface PointsOfInterestComponentState {
    visibleBookmarkedList: VisibleBookmarkedListType | undefined;
    hospitalList: AlertPointOfInterest[];
    hemsBaseList: AlertPointOfInterest[];
}

@Component({
    selector: "dats-lib-points-of-interest",
    standalone: true,
    imports: [CommonModule, SharedUiModule, LetDirective, MatTooltipModule, TranslocoPipe],
    templateUrl: "./points-of-interest.component.html",
    styleUrls: ["./points-of-interest.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PointsOfInterestComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<PointsOfInterestComponentState>);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly transloco = inject(TranslocoService);

    @Input({ required: true }) public set hospitalList(value: AlertPointOfInterest[] | undefined) {
        this.localStore.patchState({ hospitalList: value ?? [] });
    }

    @Input({ required: true }) public set hemsBaseList(value: AlertPointOfInterest[] | undefined) {
        this.localStore.patchState({ hemsBaseList: value ?? [] });
    }

    @Output() public favoriteHospitalsClick = new EventEmitter<void>();
    @Output() public favoriteHemsBasesClick = new EventEmitter<void>();
    @Output() public poiClick = new EventEmitter<AlertPointOfInterest>();

    protected readonly visibleBookmarkedList$ = this.localStore.selectByKey("visibleBookmarkedList");
    protected readonly hospitalList$ = this.localStore
        .selectByKey("hospitalList")
        .pipe(map((hospitalList) => hospitalList.filter((hospital) => hospital.isBookmarked)));
    protected readonly hemsBaseList$ = this.localStore
        .selectByKey("hemsBaseList")
        .pipe(map((hemsBaseList) => hemsBaseList.filter((hemsBase) => hemsBase.isBookmarked)));

    protected readonly VisibleBookmarkedListType = VisibleBookmarkedListType;

    constructor() {
        this.localStore.setState({
            visibleBookmarkedList: undefined,
            hospitalList: [],
            hemsBaseList: [],
        });
    }

    protected displayBookmarkedHospitals(): void {
        const isCurrentBookmarkedHospitalsVisible =
            this.localStore.selectSnapshotByKey("visibleBookmarkedList") === VisibleBookmarkedListType.Hospitals;

        if (!isCurrentBookmarkedHospitalsVisible) {
            this.store.dispatch(new AlertActions.GetAllHospitals()).subscribe(() => {
                const error = this.store.selectSnapshot(AlertState.error);

                if (error && error.type === AlertErrorType.CannotGetPointsOfInterest) {
                    this.errorHandlingService.displayMessage({
                        error,
                        errorMessage: this.transloco.translate("datsLibFlights.pointOfInterest.getHospitalErrorMessage"),
                    });

                    return;
                }

                this.localStore.patchState({ visibleBookmarkedList: VisibleBookmarkedListType.Hospitals });
            });
        } else {
            this.localStore.patchState({ visibleBookmarkedList: undefined });
        }
    }

    protected displayBookmarkedHemsBases(): void {
        const isCurrentBookmarkedHemsBasesVisible =
            this.localStore.selectSnapshotByKey("visibleBookmarkedList") === VisibleBookmarkedListType.HemsBases;

        if (!isCurrentBookmarkedHemsBasesVisible) {
            this.store.dispatch(new AlertActions.GetAllHemsBases()).subscribe(() => {
                const error = this.store.selectSnapshot(AlertState.error);

                if (error && error.type === AlertErrorType.CannotGetPointsOfInterest) {
                    this.errorHandlingService.displayMessage({
                        error,
                        errorMessage: this.transloco.translate("datsLibFlights.pointOfInterest.getHemsBaseErrorMessage"),
                    });

                    return;
                }

                this.localStore.patchState({ visibleBookmarkedList: VisibleBookmarkedListType.HemsBases });
            });
        } else {
            this.localStore.patchState({ visibleBookmarkedList: undefined });
        }
    }

    protected selectPoi(poi: AlertPointOfInterest) {
        this.localStore.patchState({ visibleBookmarkedList: undefined });
        this.poiClick.emit(poi);
    }
}
