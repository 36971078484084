import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { CheckinAction, FlightDetails } from "../../models/flight.models";

interface FlightDetailsComponentState {
    flightDetails: FlightDetails | undefined;
    isMaintainFlightsFeatureAvailable: boolean;
    isControlAirTrafficFeatureAvailable: boolean;
}

@Component({
    selector: "dats-lib-flight-details",
    templateUrl: "./flight-details.component.html",
    styleUrls: ["./flight-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightDetailsComponent {
    @Input({ required: true }) public set flightDetails(value: FlightDetails | undefined) {
        this.localStore.patchState({ flightDetails: value });
    }

    @Input() public set isMaintainFlightsFeatureAvailable(value: boolean) {
        this.localStore.patchState({ isMaintainFlightsFeatureAvailable: value });
    }

    @Input() public set isControlAirTrafficFeatureAvailable(value: boolean) {
        this.localStore.patchState({ isControlAirTrafficFeatureAvailable: value });
    }

    @Output() public readonly checkinModify = new EventEmitter<CheckinAction>();

    protected readonly flightDetails$ = this.localStore.selectByKey("flightDetails");
    protected readonly isMaintainFlightsFeatureAvailable$ = this.localStore.selectByKey("isMaintainFlightsFeatureAvailable");
    protected readonly isControlAirTrafficFeatureAvailable$ = this.localStore.selectByKey("isControlAirTrafficFeatureAvailable");

    constructor(private readonly localStore: LocalComponentStore<FlightDetailsComponentState>) {
        this.localStore.setState({
            flightDetails: undefined,
            isMaintainFlightsFeatureAvailable: false,
            isControlAirTrafficFeatureAvailable: false,
        });
    }
}
