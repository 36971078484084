<dtm-ui-expandable-panel *ngIf="pilot$ | ngrxPush as pilot" [hasHeaderSeparator]="false">
    <div headerSlot>
        {{ "datsLibFlights.missionDetails.pilotLabel" | transloco }}
    </div>
    <div class="content">
        <dtm-ui-label-value
            *ngIf="pilot.name"
            [label]="'datsLibFlights.missionDetails.pilotNameLabel' | transloco"
            [value]="pilot.name"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="pilot.mobile"
            [label]="'datsLibFlights.missionDetails.pilotMobileNumberLabel' | transloco"
            [value]="pilot.mobile"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="pilot.pilotNumber"
            [label]="'datsLibFlights.missionDetails.pilotNumberLabel' | transloco"
            [value]="pilot.pilotNumber"
        ></dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
