import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { WebsocketConnectionStatus } from "@dtm-frontend/shared/websocket";
import { UntilDestroy } from "@ngneat/until-destroy";

interface NavbarConnectionStatusComponentState {
    connectionStatus: WebsocketConnectionStatus;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-flights-navbar-connection-status",
    templateUrl: "./connection-status.component.html",
    styleUrls: ["./connection-status.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NavbarConnectionStatusComponent {
    protected readonly WebsocketConnectionStatus = WebsocketConnectionStatus;

    @Input({ required: true }) public set connectionStatus(value: WebsocketConnectionStatus) {
        this.localStore.setState({
            connectionStatus: value,
        });
    }

    protected readonly connectionStatus$ = this.localStore.selectByKey("connectionStatus");

    constructor(private readonly localStore: LocalComponentStore<NavbarConnectionStatusComponentState>) {
        this.localStore.setState({
            connectionStatus: WebsocketConnectionStatus.Disconnected,
        });
    }
}
