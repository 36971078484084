import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { PushPipe } from "@ngrx/component";
import { Uav } from "../../../models/flight.models";
import { UavMassLimitPipe } from "../../../pipes/uav-mass-limit.pipe";

interface MissionDetailsUavComponentState {
    uav: Uav | undefined;
}

@Component({
    selector: "dats-lib-mission-details-uav",
    standalone: true,
    imports: [CommonModule, PushPipe, SharedUiModule, SharedI18nModule, TranslocoModule, UavMassLimitPipe],
    templateUrl: "./mission-details-uav.component.html",
    styleUrls: ["./mission-details-uav.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDetailsUavComponent {
    @Input() public set uav(value: Uav | undefined) {
        this.localStore.patchState({ uav: value });
    }

    protected readonly uav$ = this.localStore.selectByKey("uav");

    constructor(private readonly localStore: LocalComponentStore<MissionDetailsUavComponentState>) {
        this.localStore.setState({
            uav: undefined,
        });
    }
}
