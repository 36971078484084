<ng-container
    *ngrxLet="{
        flight: flight$,
        isCheckinDataAcknowledged: isCheckinDataAcknowledged$,
        category: category$,
        isAtcController: isAtcController$,
        isMenuOpen: isMenuOpen$,
        isMaintainFlightsFeatureAvailable: isMaintainFlightsFeatureAvailable$,
        isControlAirTrafficFeatureAvailable: isControlAirTrafficFeatureAvailable$,
        flightDetailsMenu: flightDetailsMenu$,
        isClickable: isClickable$,
        isSelected: isSelected$,
        categoryClassName: categoryClassName$,
        isDetailsButtonVisible: isDetailsButtonVisible$
    } as vm"
>
    <div
        class="wrapper"
        [class]="vm.categoryClassName"
        [ngClass]="{ clickable: vm.isClickable, selected: vm.isSelected, category: vm.category }"
    >
        <div class="badges">
            <div
                class="status"
                [matTooltip]="
                    (vm.flight.progress.phase
                        ? ('datsLibFlights.flightItem.progressPhaseTooltip' | transloco : { value: vm.flight.progress.phase })
                        : '') +
                    (vm.flight.acceptance.phase
                        ? ('datsLibFlights.flightItem.acceptancePhaseTooltip' | transloco : { value: vm.flight.acceptance.phase })
                        : '') +
                    (vm.flight.acceptance.type
                        ? ('datsLibFlights.flightItem.acceptanceTypeTooltip' | transloco : { value: vm.flight.acceptance.type })
                        : '')
                "
                matTooltipPosition="above"
                matTooltipClass="flight-status-tooltip"
            >
                <dats-lib-flight-acceptance-badge
                    *ngIf="vm.flight.progress.phase === FlightProgressPhase.Uncompleted; else progressBadgeTemplate"
                    [acceptance]="vm.flight.acceptance"
                    [operationType]="vm.flight.operation.type"
                ></dats-lib-flight-acceptance-badge>
                <ng-template #progressBadgeTemplate>
                    <dats-lib-flight-progress-badge
                        [progress]="vm.flight.progress"
                        [isUnacknowledged]="vm.flight.isUnacknowledged"
                        [isSystem]="vm.flight.acceptance.isSystem"
                    ></dats-lib-flight-progress-badge>
                </ng-template>
            </div>
            <div class="info-badges">
                <span *ngIf="vm.flight.operation.isBvlos" class="chip default">
                    {{ "datsLibFlights.flightItem.bvlosBadgeLabel" | transloco }}
                </span>
                <span *ngIf="vm.flight.operation.mission" class="chip default">
                    {{ "datsLibFlights.flightItem.missionBadgeLabel" | transloco }}
                </span>
                <ng-container *ngIf="vm.isAtcController; else fisZonesBadgeTemplate">
                    <ng-container *ngIf="vm.flight | invoke : getAirspaceTypeElements : [AirspaceType.RPA] as elements">
                        <dats-lib-rpa-badge *ngIf="elements?.length" [elements]="elements"></dats-lib-rpa-badge>
                    </ng-container>
                </ng-container>
                <ng-template #fisZonesBadgeTemplate>
                    <ng-container
                        *ngIf="
                            vm.flight
                                | invoke : getAirspaceTypeElements : [AirspaceType.CTR, AirspaceType.MCTR, AirspaceType.ATZ] as elements
                        "
                    >
                        <dats-lib-fis-zones-badge *ngIf="elements?.length" [elements]="elements"></dats-lib-fis-zones-badge>
                    </ng-container>
                </ng-template>
            </div>
        </div>
        <div class="details">
            <div class="name">
                <ng-container [ngSwitch]="vm.flight.operation.type">
                    <dtm-ui-icon *ngSwitchCase="FlightOperationType.Emergency112" name="alarm-warning-fill" class="emergency"></dtm-ui-icon>
                    <dtm-ui-icon *ngSwitchCase="FlightOperationType.Ghost112" name="spy-fill" class="emergency"></dtm-ui-icon>
                    <dtm-ui-icon *ngSwitchDefault name="user"></dtm-ui-icon>
                </ng-container>
                {{ vm.flight.operation.operator.name | ellipsifyText : 50 }}
            </div>
            <div class="more">
                <button type="button" class="button-icon" (click)="openCheckinDetails()" *ngIf="vm.isDetailsButtonVisible">
                    <dtm-ui-icon name="information-fill"></dtm-ui-icon>
                </button>
                <button
                    type="button"
                    class="button-icon button-menu"
                    [class.active]="vm.isMenuOpen"
                    [matMenuTriggerFor]="flightDetailsMenu"
                    (menuOpened)="onMenuOpened()"
                    (menuClosed)="onMenuClosed()"
                    *ngIf="vm.flightDetailsMenu.length"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </div>

            <mat-menu #flightDetailsMenu="matMenu" xPosition="before">
                <div class="menu">
                    <button *ngFor="let item of vm.flightDetailsMenu" mat-menu-item type="button" (click)="item.action()">
                        <div class="details-menu-item">
                            <dtm-ui-icon [name]="item.icon.name" [class]="item.icon.class"></dtm-ui-icon>
                            <span>{{ item.labelKey | transloco }}</span>
                        </div>
                    </button>
                </div>
            </mat-menu>
        </div>
        <div class="content">
            <div class="flight-details">
                <dats-lib-time-height-display
                    [operation]="vm.flight.operation"
                    [modification]="vm.flight.modification"
                    [isEmergency]="vm.flight.progress.phase === FlightProgressPhase.Emergency"
                    [isDataAcknowledged]="vm.isCheckinDataAcknowledged"
                ></dats-lib-time-height-display>
                <dats-lib-uav-info [uav]="vm.flight.operation.uav"></dats-lib-uav-info>
            </div>
        </div>
        <dats-lib-flight-progress-bar
            *ngIf="vm.category | invoke : isProgressBarVisible"
            [category]="vm.category"
            [flightStartAt]="vm.category === FlightCategory.Emergency ? vm.flight.emergencyAt : vm.flight.operation.plannedStartAt"
            [flightEndAt]="vm.category === FlightCategory.Emergency ? vm.flight.estimatedEndAt : vm.flight.operation.plannedEndAt"
        ></dats-lib-flight-progress-bar>
        <dats-lib-confirmation-bar
            *ngIf="!vm.isCheckinDataAcknowledged"
            (resend)="confirmationResend.emit(vm.flight.id)"
        ></dats-lib-confirmation-bar>
        <dats-lib-flight-item-actions
            *ngIf="
                vm.isControlAirTrafficFeatureAvailable &&
                (vm.flight.acceptance.phase === FlightAcceptancePhase.Pending ||
                    vm.flight.acceptance.phase === FlightAcceptancePhase.Standby)
            "
            (checkinModify)="modifyCheckin($event)"
        ></dats-lib-flight-item-actions>
    </div>
</ng-container>
