<ng-container
    *ngrxLet="{
        flightOperation: flightOperation$,
    } as vm"
>
    <dtm-ui-expandable-panel *ngIf="vm.flightOperation?.airspace?.elements" [hasHeaderSeparator]="false">
        <div headerSlot>{{ "datsLibFlights.flightDetails.conflictZonesLabel" | transloco }}</div>
        <div class="content">
            <div class="chips">
                <span *ngFor="let item of vm.flightOperation?.airspace?.elements" class="chip" [ngClass]="item.chipColor">
                    {{ item.designator }}
                </span>
            </div>

            <div class="coordinates-wrapper">
                <h2>{{ "datsLibFlights.flightDetails.centerCoordinatesLabel" | transloco }}</h2>
                <p class="coordinates">
                    {{
                        "datsLibFlights.flightDetails.coordinatesLabel"
                            | transloco
                                : {
                                      latitude:
                                          vm.flightOperation?.geography?.latitude
                                          | decimalToDmsCoordinates : GeographicCoordinatesType.Latitude,
                                      longitude:
                                          vm.flightOperation?.geography?.longitude
                                          | decimalToDmsCoordinates : GeographicCoordinatesType.Longitude
                                  }
                    }}
                    <span class="decimal-coordinates">
                        {{
                            "datsLibFlights.flightDetails.coordinatesLabel"
                                | transloco
                                    : {
                                          latitude: vm.flightOperation?.geography?.latitude | number : "1.2-5",
                                          longitude: vm.flightOperation?.geography?.longitude | number : "1.2-5"
                                      }
                        }}
                    </span>
                </p>
            </div>
        </div>
    </dtm-ui-expandable-panel>
</ng-container>
