import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { ALERT_BADGES, AlertBadgeType, CategorizedAlertsByBadge } from "../../models/alert.models";

interface AlertBadgesComponentState {
    alerts: CategorizedAlertsByBadge | undefined;
    activeAlertBadge: AlertBadgeType | undefined;
}

@Component({
    selector: "dats-lib-alert-badges",
    templateUrl: "./alert-badges.component.html",
    styleUrls: ["../alert-badges.scss", "./alert-badges.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [
        trigger("slideInOut", [
            state("void", style({ transform: "translate(10%,-50%)", opacity: 0 })),
            state("*", style({ transform: "translate(0,-50%)", opacity: 1 })),
            transition(":enter", [animate("200ms ease-in-out")]),
            transition(":leave", [animate("200ms ease-in-out", style({ transform: "translate(10%,-50%)", opacity: 0 }))]),
        ]),
    ],
})
export class AlertBadgesComponent {
    private readonly localStore = inject(LocalComponentStore<AlertBadgesComponentState>);

    @Input({ required: true }) public set alerts(value: CategorizedAlertsByBadge | undefined) {
        this.localStore.patchState({ alerts: value });
    }

    protected readonly activeAlertBadge$ = this.localStore.selectByKey("activeAlertBadge");
    protected readonly alerts$ = this.localStore.selectByKey("alerts");

    protected readonly alertBadges$ = this.alerts$.pipe(
        map((alerts) => ALERT_BADGES.filter((alertBadge) => alerts?.[alertBadge.type]?.length))
    );

    constructor() {
        this.localStore.setState({ activeAlertBadge: undefined, alerts: undefined });
    }

    protected setActiveAlertBadge(newAlertBadge: AlertBadgeType) {
        const activeAlertBadge = this.localStore.selectSnapshotByKey("activeAlertBadge");

        this.localStore.patchState({
            activeAlertBadge: newAlertBadge !== activeAlertBadge ? newAlertBadge : undefined,
        });
    }
}
