import { InjectionToken } from "@angular/core";

export interface FlightsEndpoints {
    getFlights: string;
    manageFlightDetails: string;
    standbyCheckin: string;
    acceptCheckin: string;
    modifyCheckin: string;
    stopCheckin: string;
    createTechnicalCheckin: string;
    getJurisdictionMissionList: string;
    getJurisdictionMission: string;
    wsFlightsTopic: string;
}

export const FLIGHTS_ENDPOINTS = new InjectionToken<FlightsEndpoints>("Flights endpoints");
