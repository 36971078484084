import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, of } from "rxjs";

const DATS_JSON_CONTENT_TYPE = "application/vnd.pansa.dats.v1+json";

// TODO: DTATS-410 Remove after backend is ready
const TRANSLATION_URL = "/system/translations/";
const TRANSLATION_STUB = {
    dats: {
        alert: {
            nfz: {
                aircraft1: "Aircraft 1",
                hems1: "HEMS 1",
                hems2: "HEMS 2",
                hems3hospital: "HEMS 3 hospital",
                res1: "Res 1",
                uav1: "UAV 1",
            },
        },
    },
};

@Injectable()
export class DroneAirTrafficServicesInterceptor implements HttpInterceptor {
    public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler) {
        const headers = httpRequest.headers.set("content-type", DATS_JSON_CONTENT_TYPE);

        const modifiedRequest = httpRequest.clone({
            headers,
        });

        return next.handle(modifiedRequest).pipe(
            catchError((error) => {
                // TODO: DTATS-410 Remove after backend is ready
                if (error.url.includes(TRANSLATION_URL)) {
                    return of(
                        new HttpResponse({
                            body: TRANSLATION_STUB,
                            status: 200,
                            statusText: "OK",
                        })
                    );
                }

                throw error;
            })
        );
    }
}
