<dtm-ui-expandable-panel *ngIf="uav$ | ngrxPush as uav" [hasHeaderSeparator]="false">
    <div headerSlot>
        {{ "datsLibFlights.missionDetails.uavLabel" | transloco }}
    </div>
    <div class="content">
        <dtm-ui-label-value
            *ngIf="uav.model"
            [label]="'datsLibFlights.missionDetails.uavModelLabel' | transloco"
            [value]="uav.model"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="uav.type"
            [label]="'datsLibFlights.missionDetails.uavTypeLabel' | transloco"
            [value]="'datsLibFlights.missionDetails.uavTypeValue' | transloco : { value: uav.type }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="uav.takeOffMassLimit"
            [label]="'datsLibFlights.missionDetails.uavMassLimitLabel' | transloco"
            [value]="'datsLibFlights.missionDetails.uavMassLimitValue' | transloco : { value: (uav.takeOffMassLimit | uavMassLimit) }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="uav.class"
            [label]="'datsLibFlights.missionDetails.uavClassLabel' | transloco"
            [value]="'datsLibFlights.missionDetails.uavClassValue' | transloco : { value: uav.class }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="uav.tracker"
            [label]="'datsLibFlights.missionDetails.uavTrackerLabel' | transloco"
            [value]="uav.tracker"
        ></dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
