import { Pipe, PipeTransform } from "@angular/core";
import { DmsCoordinatesUtils, DmsDirectionCoordinates, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";

@Pipe({
    name: "decimalToDmsCoordinates",
    standalone: true,
})
export class DecimalToDmsCoordinatesPipe implements PipeTransform {
    private padNumberWithZeros(value: number): string {
        return value.toString().padStart(2, "0");
    }

    private convertDmsWithDirectionToString(dms: DmsDirectionCoordinates): string {
        return `${dms.direction} ${dms.degrees}° ${this.padNumberWithZeros(dms.minutes)}' ${this.padNumberWithZeros(dms.seconds)}"`;
    }

    public transform(coordinates: number | undefined, type: GeographicCoordinatesType): string {
        if (!coordinates) {
            return "";
        }

        return this.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(coordinates, type)
        );
    }
}
