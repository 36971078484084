<ng-container
    *ngrxLet="{
        selectedTabType: selectedTabType$,
        actionSidebarType: actionSidebarType$,
        isPanelProcessing: isPanelProcessing$,
        isAtcController: isAtcController$,
        flightList: flightList$,
        flightDetails: flightDetails$,
        tabs: tabs$,
        jurisdictionMissions: jurisdictionMissions$,
        zoom: zoom$,
        selectedFlightId: selectedFlightId$,
        displayDetailsId: displayDetailsId$,
        hospitalList: hospitalList$,
        hemsBaseList: hemsBaseList$,
        zoneList: zoneList$,
        appliedFilters: appliedFilters$,
        selectedAlertGeometrySourceType: selectedAlertGeometrySourceType$,
        heightFilter: heightFilter$,
        airspaceAutocompletionList: airspaceAutocompletionList$,
        isAirspaceAutocompletionProcessing: isAirspaceAutocompletionProcessing$,
        isManageAlertsFeatureAvailable: isManageAlertsFeatureAvailable$,
        isMaintainFlightsFeatureAvailable: isMaintainFlightsFeatureAvailable$,
        isControlAirTrafficFeatureAvailable: isControlAirTrafficFeatureAvailable$,
        isTestSystemFeatureAvailable: isTestSystemFeatureAvailable$,
        technicalCheckinInitialFormData: technicalCheckinInitialFormData$,
        templates: templates$,
        alerts: alerts$,
        isAlertActionProcessing: isAlertActionProcessing$,
        isActionProcessing: isActionProcessing$,
        lastSelectedPoi: lastSelectedPoi$
    } as vm"
>
    <dtm-map-leaflet-map [mapOptions]="mapOptions" (mapClick)="selectFlight(undefined, 'map')">
        <dats-lib-flights-layer
            [flightList]="vm.flightList"
            [selectedFlightId]="vm.selectedFlightId"
            [zoom]="vm.zoom"
            [heightFilter]="vm.heightFilter"
            (flightClick)="selectFlight($event, 'map')"
        ></dats-lib-flights-layer>

        <dtm-map-leaflet-context-menu #leafletContextMenu *ngIf="vm.isTestSystemFeatureAvailable">
            <mat-menu #contextMenuRoot="matMenu">
                <button
                    type="button"
                    class="technical-menu-item"
                    *ngIf="vm.isTestSystemFeatureAvailable"
                    mat-menu-item
                    (click)="openTechnicalCheckinForm(leafletContextMenu.coordinates)"
                >
                    <dtm-ui-icon name="add" />
                    {{ "datsLibFlights.flightsContainer.contextMenu.createTechnicalCheckinMenuItem" | transloco }}
                </button>
                <!-- TODO DTATS-68: remove #leafletContextMenu ngIf after adding more context menu items -->
            </mat-menu>
        </dtm-map-leaflet-context-menu>

        <ng-container mapControls>
            <button class="leaflet-button" type="button" (click)="zoomToJurisdiction()">
                <dtm-ui-icon name="home-2"></dtm-ui-icon>
            </button>
        </ng-container>
    </dtm-map-leaflet-map>
    <dats-lib-alert-geometry-panel
        [selectedAlertGeometrySourceType]="vm.selectedAlertGeometrySourceType"
        (alertGeometrySourceTypeChange)="changeAlertGeometrySourceType($event)"
    />
    <section class="sidebar" *ngIf="vm.isManageAlertsFeatureAvailable">
        <dats-lib-points-of-interest
            (favoriteHospitalsClick)="manageHospitalListPanel(true)"
            (favoriteHemsBasesClick)="manageHemsBaseListPanel(true)"
            (poiClick)="openAlertForm($event)"
            [hospitalList]="vm.hospitalList"
            [hemsBaseList]="vm.hemsBaseList"
        ></dats-lib-points-of-interest>
        <dats-lib-alert-badges [alerts]="vm.alerts" />
    </section>
    <section class="flights-panel-wrapper">
        <dats-lib-flights-panel
            [selectedTabType]="vm.selectedTabType"
            [isProcessing]="vm.isPanelProcessing"
            [flightList]="vm.flightList"
            [tabs]="vm.tabs"
            [jurisdictionMissions]="vm.jurisdictionMissions"
            [isAtcController]="vm.isAtcController"
            [appliedFilters]="vm.appliedFilters"
            [selectedFlightId]="vm.selectedFlightId"
            [displayDetailsId]="vm.displayDetailsId"
            [flightDetails]="vm.flightDetails"
            [isProcessing]="vm.isPanelProcessing"
            [isMaintainFlightsFeatureAvailable]="vm.isMaintainFlightsFeatureAvailable"
            [isControlAirTrafficFeatureAvailable]="vm.isControlAirTrafficFeatureAvailable"
            (selectedTabUpdate)="updateSelectedTab($event)"
            (checkinModify)="handleCheckinAction($event)"
            (confirmationResend)="resendConfirmation($event)"
            (selectedFlightChange)="selectFlight($event, 'list')"
            (filtersChange)="applyFilters($event)"
            (extendedMissionDataOpen)="loadExtendedMissionData($event)"
            (displayDetailsIdChanged)="handleDetailsDisplay($event)"
        ></dats-lib-flights-panel>
    </section>
    <section class="action-sidebar">
        <dats-lib-action-sidebar [isOpen]="vm.actionSidebarType" [isProcessing]="vm.isActionProcessing || vm.isAlertActionProcessing">
            <ng-container
                *ngrxLet="{
                    checkinAction: checkinAction$,
                    flight: flight$
                } as sidebar"
            >
                <dats-lib-standby-checkin-form
                    *ngIf="vm.actionSidebarType === ActionSidebarType.Checkin && sidebar.checkinAction.type === CheckinActionType.Standby"
                    [flight]="sidebar.flight"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="delayOrRejectCheckin($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                ></dats-lib-standby-checkin-form>
                <dats-lib-modify-checkin-form
                    *ngIf="vm.actionSidebarType === ActionSidebarType.Checkin && sidebar.checkinAction.type === CheckinActionType.Modify"
                    [flight]="sidebar.flight"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="modifyCheckin($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                ></dats-lib-modify-checkin-form>
            </ng-container>

            <ng-container *ngIf="vm.actionSidebarType === ActionSidebarType.Alert && vm.isManageAlertsFeatureAvailable">
                <dats-lib-airspace-alert-form
                    *ngIf="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.Airspace"
                    [zones]="vm.zoneList"
                    [templates]="vm.templates"
                    [airspaceAutocompletionList]="vm.airspaceAutocompletionList"
                    [isAirspaceAutocompletionProcessing]="vm.isAirspaceAutocompletionProcessing"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="createAlert($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                    (messageTemplatesQueryParamsChange)="getAlertProposal($event)"
                    (zoneSearchTextChange)="searchZones($event)"
                />
                <dats-lib-hospital-alert-form
                    *ngIf="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.Hospital"
                    [hospitals]="vm.hospitalList"
                    [templates]="vm.templates"
                    [selectedPoi]="vm.lastSelectedPoi && vm.lastSelectedPoi.type === PoiType.Hospital ? vm.lastSelectedPoi : undefined"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="createAlert($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                    (messageTemplatesQueryParamsChange)="getAlertProposal($event)"
                />
                <dats-lib-hems-alert-form
                    *ngIf="vm.selectedAlertGeometrySourceType === AlertGeometrySourceType.HEMS"
                    [hemsBases]="vm.hemsBaseList"
                    [templates]="vm.templates"
                    [selectedPoi]="vm.lastSelectedPoi && vm.lastSelectedPoi.type === PoiType.Hems ? vm.lastSelectedPoi : undefined"
                    (actionCancel)="tryToCloseActionSidebar()"
                    (actionConfirm)="createAlert($event)"
                    (isFormChanged)="markFormAsChanged($event)"
                    (messageTemplatesQueryParamsChange)="getAlertProposal($event)"
                />
            </ng-container>

            <dats-lib-bookmarked-poi-list
                *ngIf="vm.actionSidebarType === ActionSidebarType.Hospital"
                [poiList]="vm.hospitalList"
                [headerText]="'datsLibFlights.flightsContainer.hospitalsSidebarTitle' | transloco"
                (actionCancel)="closeActionSidebar()"
                (actionConfirm)="dispatchHospitalList($event)"
            ></dats-lib-bookmarked-poi-list>

            <dats-lib-bookmarked-poi-list
                *ngIf="vm.actionSidebarType === ActionSidebarType.HemsBase"
                [poiList]="vm.hemsBaseList"
                [headerText]="'datsLibFlights.flightsContainer.hemsBasesSidebarTitle' | transloco"
                (actionCancel)="closeActionSidebar()"
                (actionConfirm)="dispatchHemsBaseList($event)"
            ></dats-lib-bookmarked-poi-list>

            <dats-lib-height-filter-form
                *ngIf="vm.actionSidebarType === ActionSidebarType.HeightFilter"
                [heightFilter]="vm.heightFilter"
                (actionCancel)="closeActionSidebar()"
                (actionConfirm)="updateHeightFilter($event)"
                (isFormChanged)="markFormAsChanged($event)"
            ></dats-lib-height-filter-form>

            <dats-lib-create-technical-checkin-form
                *ngIf="vm.actionSidebarType === ActionSidebarType.CreateTechnicalCheckin && vm.isTestSystemFeatureAvailable"
                [initialFormData]="vm.technicalCheckinInitialFormData"
                (actionConfirm)="createTechnicalCheckin($event)"
                (actionCancel)="tryToCloseActionSidebar()"
                (isFormChanged)="markFormAsChanged($event)"
            />
        </dats-lib-action-sidebar>
    </section>
</ng-container>
