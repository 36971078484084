<dtm-ui-expandable-panel *ngIf="mission$ | ngrxPush as mission" [hasHeaderSeparator]="false">
    <div headerSlot class="header">
        {{ "datsLibFlights.missionDetails.basicDataLabel" | transloco }}
        <span class="chip" *ngIf="mission.type === MissionType.BVLOS">
            <dtm-ui-icon name="eye-off"></dtm-ui-icon> {{ "datsLibFlights.missionDetails.bvlosLabel" | transloco }}
        </span>
    </div>
    <div class="content">
        <dtm-ui-label-value
            [label]="'datsLibFlights.missionDetails.startMissionLabel' | transloco"
            [value]="mission.startDateTime | localizeDate : { timeStyle: 'short' }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'datsLibFlights.missionDetails.endMissionLabel' | transloco"
            [value]="mission.endDateTime | localizeDate : { timeStyle: 'short' }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'datsLibFlights.missionDetails.maxHeightLabel' | transloco"
            [value]="
                'datsLibFlights.missionDetails.maxHeight'
                    | transloco : { value: mission.maxHeight | localizeNumber : { maximumFractionDigits: 0 } }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="mission.flightArea?.length"
            [label]="'datsLibFlights.missionDetails.missionLengthLabel' | transloco"
            [value]="
                'datsLibFlights.missionDetails.missionLength'
                    | transloco : { value: mission.flightArea?.length | localizeNumber : { maximumFractionDigits: 0 } }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="!mission.flightArea?.length && mission.flightArea?.maxRadius"
            [label]="'datsLibFlights.missionDetails.missionRadiusLabel' | transloco"
            [value]="
                'datsLibFlights.missionDetails.missionRadius'
                    | transloco : { value: mission.flightArea?.maxRadius | localizeNumber : { maximumFractionDigits: 0 } }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'datsLibFlights.missionDetails.operationCategoryLabel' | transloco"
            [value]="'datsLibFlights.missionDetails.operationCategory' | transloco : { value: mission.flightCategory }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'datsLibFlights.missionDetails.missionTypeLabel' | transloco"
            [value]="'datsLibFlights.missionDetails.missionType' | transloco : { value: mission.type }"
        ></dtm-ui-label-value>
    </div>
    <dtm-ui-label-value
        [label]="'datsLibFlights.missionDetails.missionDescriptionLabel' | transloco"
        [value]="mission.additionalInfo"
    ></dtm-ui-label-value>
</dtm-ui-expandable-panel>
