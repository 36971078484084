import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { PushPipe } from "@ngrx/component";
import { Operator } from "../../../models/flight.models";

interface MissionDetailsOperatorComponentState {
    operator: Operator | undefined;
}

@Component({
    selector: "dats-lib-mission-details-operator",
    standalone: true,
    imports: [CommonModule, PushPipe, SharedUiModule, SharedI18nModule, TranslocoModule],
    templateUrl: "./mission-details-operator.component.html",
    styleUrls: ["./mission-details-operator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDetailsOperatorComponent {
    @Input() public set operator(value: Operator | undefined) {
        this.localStore.patchState({ operator: value });
    }

    protected readonly operator$ = this.localStore.selectByKey("operator");

    constructor(private readonly localStore: LocalComponentStore<MissionDetailsOperatorComponentState>) {
        this.localStore.setState({
            operator: undefined,
        });
    }
}
