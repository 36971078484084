import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetDirective, PushPipe } from "@ngrx/component";
import equal from "fast-deep-equal";
import { debounceTime } from "rxjs";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { Zone } from "../../../models/alert.models";

const MIN_SEARCH_QUERY_LENGTH = 3;

interface AlertZoneComponentState {
    options: Zone[];
    isProcessing: boolean;
    placeholder: string;
    selectedValue: Zone | null;
}

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-zone",
    standalone: true,
    imports: [
        CommonModule,
        SharedUiModule,
        TranslocoModule,
        SharedI18nModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule,
        PushPipe,
        LetDirective,
        MatProgressSpinnerModule,
    ],
    templateUrl: "./alert-zone.component.html",
    styleUrls: ["./alert-zone.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AlertZoneComponent),
            multi: true,
        },
    ],
})
export class AlertZoneComponent implements ControlValueAccessor {
    @Input() public set options(value: Zone[]) {
        this.localStore.patchState({ options: value ?? [] });
    }

    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Output() public readonly searchTextChange = new EventEmitter<string>();

    public readonly options$ = this.localStore.selectByKey("options");
    public readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    public readonly selectedValue$ = this.localStore.selectByKey("selectedValue");

    constructor(private readonly localStore: LocalComponentStore<AlertZoneComponentState>) {
        this.localStore.setState({
            options: [],
            isProcessing: false,
            placeholder: "",
            selectedValue: null,
        });

        this.zoneFormControl.valueChanges
            .pipe(
                distinctUntilChanged(equal),
                tap((value) => {
                    if (value && !this.isSearchQueryValue(value)) {
                        this.propagateChange(value);
                        this.localStore.patchState({ selectedValue: value });
                    }
                }),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                tap((value) => {
                    if (this.isSearchQueryValue(value) && value.length >= MIN_SEARCH_QUERY_LENGTH) {
                        this.searchTextChange.next(value);
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public writeValue(value: Zone | null): void {
        this.zoneFormControl.setValue(value, { emitEvent: false });
    }

    protected readonly zoneFormControl = new FormControl<Zone | string | null>(null);

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: Zone) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public registerOnChange(fn: (value: Zone) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public selectValue(event: MatAutocompleteSelectedEvent): void {
        this.zoneFormControl.setValue(event.option.value, { emitEvent: false });
    }

    public displayAutocompleteValueFn(value: Zone): string {
        return value?.designator ?? "";
    }

    private isSearchQueryValue(value: unknown): value is string {
        return typeof value === "string";
    }
}
