import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { SYSTEM_TRANSLATION_SCOPE, SystemTranslationPipe } from "@dtm-frontend/shared/ui/i18n";
import { FormUtils, ISO8601TimeDuration, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { combineLatest, distinctUntilChanged, firstValueFrom, map } from "rxjs";
import { AlertTextTemplate, AlertType } from "../../../models/alert.models";
import { AlertTimeRange } from "../alert-time-range/alert-time-range.component";

interface AlertFormComponentState {
    templates: AlertTextTemplate[];
    notModifiedMessageFromTemplate: string | null;
    isMessageTemplateIdSelectionDisabled: boolean;
}

export interface AlertFormGroup {
    type: FormControl<AlertType>;
    timeRange: FormControl<AlertTimeRange | null>;
    messageTemplateId: FormControl<string | null>;
    message: FormControl<string | null>;
    isMessageModified: FormControl<boolean>;
}

const MAX_MESSAGE_LENGTH = 200;

@UntilDestroy()
@Component({
    selector: "dats-lib-alert-form",
    templateUrl: "./alert-form.component.html",
    styleUrls: ["./alert-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, SystemTranslationPipe],
})
export class AlertFormComponent {
    @Input() public set alertType(value: AlertType) {
        this.alertForm.controls.type.setValue(value);
    }
    @Input({ required: true }) public set templates(value: AlertTextTemplate[]) {
        if (!value.find((template) => template.value === this.alertForm.controls.messageTemplateId.value)) {
            this.resetTemplateIdState();
        }
        this.localStore.patchState({ templates: value });
    }
    @Input() public set isMessageTemplateIdSelectionDisabled(value: boolean) {
        this.localStore.patchState({ isMessageTemplateIdSelectionDisabled: value });
    }

    public readonly alertForm = new FormGroup<AlertFormGroup>({
        type: new FormControl<AlertType>(AlertType.NoFlyZone, { nonNullable: true }),
        messageTemplateId: new FormControl<string | null>({ value: null, disabled: true }, { nonNullable: false }),
        message: new FormControl<string | null>(null, [Validators.maxLength(MAX_MESSAGE_LENGTH)]),
        timeRange: new FormControl<AlertTimeRange | null>(null, { nonNullable: true }),
        isMessageModified: new FormControl<boolean>(false, { nonNullable: true }),
    });

    @Output() public readonly valueChange = this.alertForm.valueChanges.pipe(distinctUntilChanged(equal));
    @Output() public readonly statusChange = this.alertForm.statusChanges.pipe(distinctUntilChanged());

    protected readonly AlertType = AlertType;
    protected readonly MAX_MESSAGE_LENGTH = MAX_MESSAGE_LENGTH;

    protected readonly templates$ = this.localStore.selectByKey("templates");

    constructor(
        private readonly localStore: LocalComponentStore<AlertFormComponentState>,
        private readonly systemTranslationPipe: SystemTranslationPipe,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            templates: [],
            notModifiedMessageFromTemplate: null,
            isMessageTemplateIdSelectionDisabled: false,
        });

        this.synchronizeDisabledStatusForMessageTemplateId();
        this.synchronizeIsMessageModified();
    }

    protected async setMessageFromTemplate(templateId: string) {
        const oldTemplateId = this.alertForm.controls.messageTemplateId.value;
        if (
            (this.alertForm.controls.message.value && this.alertForm.controls.isMessageModified.value) ||
            (!this.alertForm.controls.messageTemplateId.value && this.alertForm.controls.message.value)
        ) {
            if (!(await this.confirmDataLoss())) {
                this.alertForm.controls.messageTemplateId.setValue(oldTemplateId);

                return;
            }
        }

        const selectedTemplate = this.localStore.selectSnapshotByKey("templates").find((template) => template.value === templateId);
        if (!selectedTemplate) {
            return;
        }

        const translatedTemplateContent = this.systemTranslationPipe.transform(selectedTemplate?.value);
        if (translatedTemplateContent !== `${SYSTEM_TRANSLATION_SCOPE}.${templateId}`) {
            this.localStore.patchState({ notModifiedMessageFromTemplate: translatedTemplateContent });
            this.alertForm.controls.message.setValue(translatedTemplateContent);
        }
    }

    protected setAlertType(type: AlertType) {
        if (type === AlertType.Message) {
            this.alertForm.controls.message.addValidators(FormUtils.trimmedRequiredValidator);
            this.alertForm.controls.message.updateValueAndValidity();
            this.alertForm.controls.message.markAsUntouched();
        } else {
            this.alertForm.controls.message.removeValidators(FormUtils.trimmedRequiredValidator);
            this.alertForm.controls.message.updateValueAndValidity();
            this.alertForm.controls.message.markAsTouched();
        }
    }

    protected setLimitedTimeRange(): void {
        this.alertForm.controls.timeRange.setValue({
            startDate: this.alertForm.controls.timeRange.value?.startDate ?? new Date(),
            duration: "PT1H" as ISO8601TimeDuration,
        });
    }

    private resetTemplateIdState(): void {
        this.alertForm.controls.messageTemplateId.setValue(null);
        this.alertForm.controls.isMessageModified.setValue(false);
    }

    private synchronizeIsMessageModified(): void {
        this.alertForm.controls.message.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (this.alertForm.controls.messageTemplateId.value) {
                this.alertForm.controls.isMessageModified.setValue(
                    this.localStore.selectSnapshotByKey("notModifiedMessageFromTemplate") !== value
                );
            }
        });
    }

    private synchronizeDisabledStatusForMessageTemplateId(): void {
        combineLatest([this.localStore.selectByKey("isMessageTemplateIdSelectionDisabled"), this.templates$])
            .pipe(untilDestroyed(this))
            .subscribe(([isMessageTemplateIdSelectionDisabled, templates]) => {
                if (isMessageTemplateIdSelectionDisabled || templates.length === 0) {
                    this.alertForm.controls.messageTemplateId.disable();
                } else {
                    this.alertForm.controls.messageTemplateId.enable();
                }
            });
    }

    protected async confirmDataLoss() {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("datsLibAlert.alertForms.messageLossDialog.titleText"),
                confirmationText: this.transloco.translate("datsLibAlert.alertForms.messageLossDialog.confirmationText"),
                declineButtonLabel: this.transloco.translate("datsLibAlert.alertForms.messageLossDialog.declineButtonLabel"),
                confirmButtonLabel: this.transloco.translate("datsLibAlert.alertForms.messageLossDialog.confirmationButtonLabel"),
                theme: ButtonTheme.Warn,
            },
        });

        return await firstValueFrom(dialogRef.afterClosed().pipe(map(Boolean), untilDestroyed(this))).catch(() => false);
    }
}
