<dats-lib-action-sidebar-header (actionCancel)="actionCancel.emit()">
    {{ "datsLibFlights.createTechnicalCheckinForm.actionSidebarHeader" | transloco }}
</dats-lib-action-sidebar-header>
<div class="action-sidebar-content scroll-shadows">
    <div class="coordinates-input-container">
        <dtm-ui-dms-coordinates-input
            [inputLabel]="'datsLibFlights.createTechnicalCheckinForm.latitudeLabel' | transloco"
            [formControl]="technicalCheckinDataForm.controls.latitude"
            [geographicCoordinatesType]="GeographicCoordinatesType.Latitude"
            [fixedDirection]="GeographicCoordinatesDirection.North"
            [isClearable]="false"
        />
        <dtm-ui-dms-coordinates-input
            [inputLabel]="'datsLibFlights.createTechnicalCheckinForm.longitudeLabel' | transloco"
            [formControl]="technicalCheckinDataForm.controls.longitude"
            [geographicCoordinatesType]="GeographicCoordinatesType.Longitude"
            [fixedDirection]="GeographicCoordinatesDirection.East"
            [isClearable]="false"
        />
    </div>
    <dtm-ui-form-field>
        <label>{{ "datsLibFlights.createTechnicalCheckinForm.pilotIdLabel" | transloco }}</label>
        <input matInput type="text" [formControl]="technicalCheckinDataForm.controls.pilotId" />
        <div class="field-error" *dtmUiFieldHasError="technicalCheckinDataForm.controls.pilotId; name: 'maxlength'; error as error">
            {{ "datsLibFlights.createTechnicalCheckinForm.maxLengthError" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-form-field>

    <dtm-ui-select-field [formControl]="technicalCheckinDataForm.controls.uavMass" [isClearable]="false">
        <label>{{ "datsLibFlights.createTechnicalCheckinForm.uavMassLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let uavMass of UAV_MASS_CATEGORIES" [value]="uavMass">{{
            "datsLibFlights.createTechnicalCheckinForm.uavMassOptionLabel" | transloco : { uavMass }
        }}</dtm-ui-select-option>
    </dtm-ui-select-field>

    <dtm-ui-expanded-number-input
        [formControl]="technicalCheckinDataForm.controls.flightHeight"
        [minValue]="1"
        [maxValue]="MAX_FLIGHT_HEIGHT"
        [stepValue]="HEIGHT_STEP"
        [valueSuffix]="'datsLibFlights.createTechnicalCheckinForm.flightHeightSuffix' | transloco"
    >
        <label>{{ "datsLibFlights.createTechnicalCheckinForm.flightHeightLabel" | transloco }}</label>
        <div class="field-error" *dtmUiFieldHasError="technicalCheckinDataForm.controls.flightHeight; name: 'min'; error as error">
            {{ "datsLibFlights.createTechnicalCheckinForm.flightHeightMinErrorMessage" | transloco : { min: error.min.min } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="technicalCheckinDataForm.controls.flightHeight; name: 'max'; error as error">
            {{ "datsLibFlights.createTechnicalCheckinForm.flightHeightMaxErrorMessage" | transloco : { max: error.max } }}
        </div>
    </dtm-ui-expanded-number-input>
    <dtm-ui-expanded-number-input
        [formControl]="technicalCheckinDataForm.controls.flightRadius"
        [minValue]="1"
        [maxValue]="MAX_FLIGHT_RADIUS"
        [stepValue]="RADIUS_STEP"
        [valueSuffix]="'datsLibFlights.createTechnicalCheckinForm.flightRadiusSuffix' | transloco"
    >
        <label>{{ "datsLibFlights.createTechnicalCheckinForm.flightRadiusLabel" | transloco }}</label>
        <div class="field-error" *dtmUiFieldHasError="technicalCheckinDataForm.controls.flightRadius; name: 'min'; error as error">
            {{ "datsLibFlights.createTechnicalCheckinForm.flightRadiusMinErrorMessage" | transloco : { min: error.min.min } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="technicalCheckinDataForm.controls.flightRadius; name: 'max'; error as error">
            {{ "datsLibFlights.createTechnicalCheckinForm.flightRadiusMaxErrorMessage" | transloco : { max: error.max } }}
        </div>
    </dtm-ui-expanded-number-input>

    <dtm-ui-select-field
        [formControl]="technicalCheckinDataForm.controls.category"
        [isClearable]="false"
        (selectionChange)="setDefaultSubcategory($event.value)"
    >
        <label>{{ "datsLibFlights.createTechnicalCheckinForm.categoryLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let value of MISSION_CATEGORIES" [value]="value">
            {{ "datsLibFlights.createTechnicalCheckinForm.missionFlightCategoryLabel" | transloco : { value } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>

    <dtm-ui-select-field
        [formControl]="technicalCheckinDataForm.controls.subCategory"
        [isClearable]="false"
        *ngIf="technicalCheckinDataForm.controls.category.value === MissionFlightCategory.Open"
    >
        <label>{{ "datsLibFlights.createTechnicalCheckinForm.subCategoryLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let subCategory of OPEN_MISSION_SUB_CATEGORIES" [value]="subCategory">{{
            subCategory
        }}</dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        [formControl]="technicalCheckinDataForm.controls.subCategory"
        [isClearable]="false"
        *ngIf="technicalCheckinDataForm.controls.category.value === MissionFlightCategory.Specific"
    >
        <label>{{ "datsLibFlights.createTechnicalCheckinForm.subCategoryLabel" | transloco }}</label>

        <dtm-ui-select-option *ngFor="let subCategory of SPECIFIC_MISSION_SUB_CATEGORIES" [value]="subCategory">{{
            "datsLibFlights.createTechnicalCheckinForm.specificMissionSubCategoryOptionLabel" | transloco : { subCategory }
        }}</dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-expanded-number-input
        [formControl]="technicalCheckinDataForm.controls.duration"
        [minValue]="1"
        [maxValue]="MAX_DURATION"
        [stepValue]="DURATION_STEP"
        [valueSuffix]="'datsLibFlights.createTechnicalCheckinForm.durationSuffix' | transloco"
    >
        <label>{{ "datsLibFlights.createTechnicalCheckinForm.durationLabel" | transloco }}</label>
        <div
            class="field-hint"
            *ngIf="technicalCheckinDataForm.controls.duration.value && technicalCheckinDataForm.controls.duration.value >= MINUTES_IN_HOUR"
        >
            {{ technicalCheckinDataForm.controls.duration.value | invoke : getISO8601TimeDuration | duration : "short" }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="technicalCheckinDataForm.controls.duration; name: 'min'; error as error">
            {{ "datsLibFlights.createTechnicalCheckinForm.durationMinErrorMessage" | transloco : { min: error.min.min } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="technicalCheckinDataForm.controls.duration; name: 'max'; error as error">
            {{
                "datsLibFlights.createTechnicalCheckinForm.durationMaxErrorMessage"
                    | transloco
                        : {
                              max: error.max,
                              textual:
                                  error.max <= MINUTES_IN_HOUR ? 0 : (error.max | invoke : getISO8601TimeDuration | duration : "short" : "")
                          }
            }}
        </div>
    </dtm-ui-expanded-number-input>
</div>
<dats-lib-action-sidebar-footer (actionCancel)="actionCancel.emit()" (actionConfirm)="tryConfirm()">
    <button *ngIf="hasFormChanged$ | ngrxPush" type="button" class="button-secondary" (click)="restoreInitialData()">
        <dtm-ui-icon name="refresh"></dtm-ui-icon>
        {{ "datsLibFlights.createTechnicalCheckinForm.restoreButtonLabel" | transloco }}
    </button>
</dats-lib-action-sidebar-footer>
