import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { PushPipe } from "@ngrx/component";
import { Pilot } from "../../../models/flight.models";

interface MissionDetailsPilotComponentState {
    pilot: Pilot | undefined;
}

@Component({
    selector: "dats-lib-mission-details-pilot",
    standalone: true,
    imports: [CommonModule, PushPipe, SharedUiModule, SharedI18nModule, TranslocoModule],
    templateUrl: "./mission-details-pilot.component.html",
    styleUrls: ["./mission-details-pilot.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDetailsPilotComponent {
    @Input() public set pilot(value: Pilot | undefined) {
        this.localStore.patchState({ pilot: value });
    }

    protected readonly pilot$ = this.localStore.selectByKey("pilot");

    constructor(private readonly localStore: LocalComponentStore<MissionDetailsPilotComponentState>) {
        this.localStore.setState({
            pilot: undefined,
        });
    }
}
