<ng-container
    *ngrxLet="{
        filteredBookmarkedPois: filteredBookmarkedPois$,
        filteredOtherPois: filteredOtherPois$
    } as vm"
>
    <dats-lib-action-sidebar-header (actionCancel)="actionCancel.emit()">
        {{ headerText$ | ngrxPush }}
    </dats-lib-action-sidebar-header>

    <div class="action-sidebar-content scroll-shadows">
        <dtm-ui-input-field isClearable>
            <input
                matInput
                type="text"
                [formControl]="searchControl"
                [placeholder]="'datsLibFlights.pointOfInterest.bookmarkedList.searchPlaceholder' | transloco"
                [attr.aria-label]="'datsLibFlights.pointOfInterest.bookmarkedList.searchPlaceholder' | transloco"
            />
            <dtm-ui-icon name="search" class="field-suffix"></dtm-ui-icon>
            <div class="field-error" *dtmUiFieldHasError="searchControl; name: 'noResults'">
                {{ "datsLibFlights.pointOfInterest.bookmarkedList.noResultsError" | transloco }}
            </div>
        </dtm-ui-input-field>

        <ng-container *ngIf="vm.filteredBookmarkedPois.length > 0">
            <h2 class="list-header">
                {{
                    "datsLibFlights.pointOfInterest.bookmarkedList.bookmarkedLabel"
                        | transloco : { count: vm.filteredBookmarkedPois.length }
                }}
            </h2>
            <ul class="list">
                <li
                    class="poi-list-item"
                    [ngClass]="{ bookmarked: poi.isBookmarked }"
                    *ngFor="let poi of vm.filteredBookmarkedPois"
                    (click)="markPoi(poi)"
                >
                    <dtm-ui-icon [name]="poi.isBookmarked ? 'star-fill' : 'star'"></dtm-ui-icon>
                    <span class="name">{{ poi.title }}</span>
                </li>
            </ul>
        </ng-container>

        <ng-container *ngIf="vm.filteredOtherPois.length > 0">
            <h2 class="list-header">
                {{ "datsLibFlights.pointOfInterest.bookmarkedList.otherLabel" | transloco : { count: vm.filteredOtherPois.length } }}
            </h2>
            <ul class="list">
                <li class="poi-list-item" *ngFor="let poi of vm.filteredOtherPois" (click)="markPoi(poi)">
                    <dtm-ui-icon [name]="poi.isBookmarked ? 'star-fill' : 'star'"></dtm-ui-icon>
                    <span class="name">{{ poi.title }}</span>
                </li>
            </ul>
        </ng-container>
    </div>

    <dats-lib-action-sidebar-footer (actionCancel)="actionCancel.emit()" (actionConfirm)="confirm()"></dats-lib-action-sidebar-footer>
</ng-container>
