import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatest, map, startWith } from "rxjs";
import { AlertPointOfInterest } from "../../../../alert/models/alert.models";

interface BookmarkedPoiListComponentState {
    poiList: AlertPointOfInterest[];
    headerText: string;
}

@Component({
    selector: "dats-lib-bookmarked-poi-list",
    templateUrl: "./bookmarked-poi-list.component.html",
    styleUrls: ["../../../../shared/components/sidebar/action-sidebar.scss", "./bookmarked-poi-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class BookmarkedPoiListComponent {
    @Input({ required: true }) public set poiList(value: AlertPointOfInterest[] | undefined) {
        this.localStore.patchState({ poiList: value ?? [] });
    }
    @Input({ required: true }) public set headerText(value: string) {
        this.localStore.patchState({ headerText: value });
    }

    @Output() public readonly actionConfirm = new EventEmitter<AlertPointOfInterest[]>();
    @Output() public readonly actionCancel = new EventEmitter<void>();

    protected readonly searchControl = new FormControl<string>("", [], this.checkIfQueryReturnsResults.bind(this));

    private readonly poiList$ = this.localStore.selectByKey("poiList");
    private readonly searchRegex$ = this.searchControl.valueChanges.pipe(
        startWith(this.searchControl.value),
        map((value) => new RegExp(value ?? "", "i"))
    );

    protected readonly headerText$ = this.localStore.selectByKey("headerText");
    protected readonly filteredBookmarkedPois$ = combineLatest([this.poiList$, this.searchRegex$]).pipe(
        map(([poiList, searchRegex]) => poiList.filter((poi) => poi.isBookmarked && searchRegex.test(poi.title)))
    );
    protected readonly filteredOtherPois$ = combineLatest([this.poiList$, this.searchRegex$]).pipe(
        map(([poiList, searchRegex]) => poiList.filter((poi) => !poi.isBookmarked && searchRegex.test(poi.title)))
    );

    constructor(private readonly localStore: LocalComponentStore<BookmarkedPoiListComponentState>) {
        this.localStore.setState({ poiList: [], headerText: "" });
    }

    private async checkIfQueryReturnsResults(control: AbstractControl<string>): Promise<ValidationErrors | null> {
        const pois = this.localStore.selectSnapshotByKey("poiList");

        if (!pois) {
            return null;
        }

        const searchRegex = new RegExp(control.value ?? "", "i");
        const filteredPois = pois.filter((poi) => searchRegex.test(poi.title));

        return filteredPois.length > 0 ? null : { noResults: true };
    }

    protected confirm() {
        const pois = this.localStore.selectSnapshotByKey("poiList");
        if (!pois) {
            return;
        }

        this.actionConfirm.emit(pois);
    }

    protected markPoi(poi: AlertPointOfInterest): void {
        const currentPoiList = this.localStore.selectSnapshotByKey("poiList");

        if (!currentPoiList) {
            return;
        }

        const poiIndex = currentPoiList.findIndex((element) => element.id === poi.id);

        if (poiIndex !== -1) {
            const poiList = [...currentPoiList];

            const updatedPoi = {
                ...poiList[poiIndex],
                isBookmarked: !poiList[poiIndex].isBookmarked,
            };

            poiList[poiIndex] = updatedPoi;

            this.localStore.patchState({ poiList });
        }
    }
}
